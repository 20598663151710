import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setFilters, setLevel, setSubject } from "../../../store/search";
import axios from "axios";
import { Autocomplete, TextField, MenuItem } from "@mui/material";

const SubjectSearch = ({ setSubject, setSelectedLevel }) => {
  const [subjects, setSubjects] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [availableLevels, setAvailableLevels] = useState([]);
  const [isFetchingSubjects, setIsFetchingSubjects] = useState(false);

  const [level, setLevel] = useState(""); // Make sure level has a default value

  const dispatch = useDispatch(); // Use the dispatch hook

  // Fetch subjects from API
  useEffect(() => {
    const fetchSearchSubjects = async () => {
      setIsFetchingSubjects(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}/api/subjects`
        );
        setSubjects(response.data.subjects);
        setFilteredSubjects(response.data.subjects); // Default to first 10 subjects
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
      setIsFetchingSubjects(false);
    };

    fetchSearchSubjects();
  }, []);

  // Handle subject selection
  const handleSubjectChange = (_, value) => {
    setSelectedSubject(value);
    setSubject(value);
    setSelectedLevel(""); // Reset the level when a new subject is selected
    const subjectData = subjects.find((sub) => sub.name === value);
    setAvailableLevels(subjectData ? subjectData.levels : []);
    setLevel(""); // Update the state with the selected value

    // Update the Redux filters with the selected subject
    dispatch(setFilters({ subject: value, level: "" })); // Clear level as subject changes
  };

  // Handle the input change of the Autocomplete component
  const handleSubjectInputChange = async (_, value) => {
    if (value.length > 2) {
      try {
        console.log("Filtering subjects with query:", value);
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER}/api/subjects?search=${value}`
        );
        console.log("Filtered subjects:", response.data.subjects);
        setFilteredSubjects(response.data.subjects);
      } catch (error) {
        console.error("Error fetching filtered subjects:", error);
      }
    } else {
      setFilteredSubjects(subjects); // Reset to the first 10 subjects
    }
  };

  // Handle level selection and update filters
  const handleLevelChange = (e) => {
    const selectedLevel = e.target.value;
    setSelectedLevel(selectedLevel);
    setLevel(e.target.value); // Update the state with the selected value

    // Update Redux store with selected level
    dispatch(setFilters({ level: selectedLevel }));
  };

  return (
    <div className="searchbar">
      <div className="inputs-container">
        <Autocomplete
          options={filteredSubjects.map((subject) => subject.name)}
          value={selectedSubject}
          onInputChange={handleSubjectInputChange} // Use the updated handler
          onChange={handleSubjectChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enter your subject"
              variant="outlined"
              className="subject-input"
            />
          )}
        />

        {selectedSubject && (
          <TextField
            select
            label="Level"
            value={level || ""} // Ensure level is set to a valid value or empty string
            variant="outlined"
            className="level-input"
            onChange={handleLevelChange} // Use the level change handler
          >
            {availableLevels.map((level) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </TextField>
        )}
      </div>
    </div>
  );
};

export default SubjectSearch;
