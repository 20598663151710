import React, { createContext, useState } from "react";

export const CreateCourseContext = createContext();

export const CourseContext = ({ children }) => {
  const [sendReq, setsendReq] = useState(false);
  const [coursePrice, setCoursePrice] = useState("");
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [chargeId, setChargeId] = useState(null);
  return (
    <CreateCourseContext.Provider
      value={{
        sendReq,
        setsendReq,
        coursePrice,
        setCoursePrice,
        isPaymentSuccessful,
        setIsPaymentSuccessful,
      }}
    >
      {children}
    </CreateCourseContext.Provider>
  );
};
