import React, { useState, useEffect, useContext } from "react";
import "./CourseBilling.css";
import CircleSvg from "../../assets/images/circle-container.svg";
import { TextField, InputAdornment, Checkbox } from "@mui/material";
import { CreditCard } from "@mui/icons-material";
import { IMaskInput } from "react-imask";
import CountrySelect from "../../Components/CountrySelect";
import { LoadingButton } from "@mui/lab";
import { createAxiosInstance } from "../../api";
import { useNavigate } from "react-router-dom";
import { showErrorMessage } from "../../utils";
import { CreateCourseContext } from "../AddCourse/AddCourseContext/AddContextContext";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Grid from "@mui/material/Grid";

const TextMaskCustom0 = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#### #### #### ####"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { value } })}
      overwrite
    />
  );
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="##/##"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { value } })}
      overwrite
    />
  );
});

const TextMaskCustom1 = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="###"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { value } })}
      overwrite
    />
  );
});

const CourseBilling = ({ advancePrice, duration }) => {
  const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const stripePromise = loadStripe(stripePublicKey); // Replace with your actual Stripe publishable key

  console.log("Stripe Public Key:", process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const stripe = useStripe();
  const elements = useElements();

  const {
    setsendReq,
    sendReq,
    coursePrice,
    setCoursePrice,
    setIsPaymentSuccessful,
    isPaymentSuccessful,
  } = useContext(CreateCourseContext);
  const [error, setError] = useState({});
  const [name, setName] = useState("");
  const [card, setCard] = useState("");
  const [expire, setExpire] = useState("");
  const [cvc, setCvc] = useState("");
  const [country, setCountry] = useState("GB");
  const [accept, setAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(coursePrice);

  const tokenData = JSON.parse(localStorage.getItem("Token"));
  //const userId = tokenData?.userInfo?._id;
  //const userIdnew = useSelector((state) => state.profile.user.user);

  //console.log("userId", userId);

  let userId;
  try {
    const persistedData = localStorage.getItem("persist:root");
    const userData = JSON.parse(persistedData);
    if (userData.userId && typeof userData.userId === "string") {
      userData.userId = userData.userId.replace(/\\/g, "");
    }
    userId = userData?.userId;
  } catch (error) {
    console.error("Error parsing localStorage data:", error);
    userId = null;
  }

  let userIdnew = userId.replace(/"/g, ""); // Remove extra quotes;

  console.log("userIdnew", userIdnew);

  const cardElementStyles = {
    base: {
      backgroundColor: "light-grey",
      fontSize: "18px",
      color: "#32325d",
      "::placeholder": {
        color: "#aab7c4",
      },
      border: "1px solid #ccc",
      borderRadius: "4px",
      padding: "10px",
      marginBottom: "16px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
    focus: {
      borderColor: "#712d93",
    },
  };

  useEffect(() => {
    if (coursePrice) {
      const numericValue = parseFloat(coursePrice.replace("£", "")) * 100;
      setPrice(Math.round(numericValue));
    }
  }, [coursePrice]);

  useEffect(() => {
    console.log("price", price);
  }, [price]);

  useEffect(() => {
    console.log("SetSendReq", setsendReq);
  }, [setsendReq]);

  const handleError = () => {
    let newError = {};
    const body = {
      card: card.replace(/ /g, ""),
      expire: expire.replace(/\//g, ""),
      cvc,
    };
    card.replace(/ /g, "");
    if (name.trim().length === 0) newError.name = "Please input your name";
    if (body.card.trim().length !== 16)
      newError.card = "Please input valid card number";
    if (body.card.trim().length === 0) newError.card = "Please input your name";
    if (body.expire.trim().length === 0)
      newError.expire = "Please input your name";
    else if (body.expire.trim().length !== 4)
      newError.expire = "Please input valid value";
    else {
      // Get the current date
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // January is represented by 0, so we add 1 to get the actual month number
      const currentYear = currentDate.getFullYear();

      // Convert the expire value into month and year
      const expire_month = parseInt(body.expire.trim().slice(0, 2));
      const expire_year = 2000 + parseInt(body.expire.trim().slice(2));

      // Check if the expiration date is before the current date
      const isExpired =
        expire_year < currentYear ||
        (expire_year === currentYear && expire_month < currentMonth);

      if (expire_month < 1 || expire_month > 12 || isExpired)
        newError.expire = "Please input valid value";
    }
    if (body.cvc.trim().length < 3) newError.cvc = "Please input valid value";
    if (body.cvc.trim().length === 0) newError.cvc = "Please input your name";
    setError(newError);
    if (Object.keys(newError).length > 0) return null;
    return body;
  };

  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      if (!stripe || !elements) {
        console.error("Stripe or Elements not available.");
        showErrorMessage("Payment processing is not available at this time.");
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      if (!cardElement) {
        console.error("Card element is missing.");
        showErrorMessage("Card details are missing. Please enter them.");
        return;
      }

      setLoading(true);

      console.log("Attempting to create Stripe token...");
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        console.error("Stripe token creation error:", error.message);
        Swal.fire({
          icon: "error",
          title: "Payment Failed",
          text: error.message,
        });
        return;
      }

      console.log("Stripe token created successfully:", token);
      await charge(token.id);
    } catch (err) {
      console.error("Unexpected error in handleClick:", err);
      showErrorMessage(err.message || "An error occurred during payment.");
    } finally {
      setLoading(false);
      console.log("Loading state set to false.");
    }
  };

  const charge = async (token) => {
    try {
      // Ensure user is logged in
      if (!userIdnew) {
        console.error("Error: User is not logged in.", userIdnew);
        Swal.fire({
          icon: "error",
          title: "Payment Error",
          text: "User not logged in. Please sign in and try again.",
          confirmButtonColor: "#0099FF",
        });
        return;
      }

      console.log("Preparing to send payment request:", {
        userId: userIdnew,
        coursePrice: advancePrice,
        token,
        purchaseType: "advert",
      });

      // Create Axios instance
      const axiosInstance = createAxiosInstance();
      if (!axiosInstance) {
        throw new Error("Axios instance could not be created.");
      }

      // Send payment request
      const response = await axiosInstance.post("/api/billings/createAdvert/", {
        userId: userIdnew,
        coursePrice: advancePrice,
        token,
        purchaseType: "advert",
      });

      console.log("Backend response received:", response?.data);

      if (response?.data?.success) {
        setsendReq(true);
        setIsPaymentSuccessful(true);

        console.log("IsPaymentSuccessful modal", isPaymentSuccessful);
      } else {
        throw new Error(
          response?.data?.message || "Payment processing failed."
        );
      }
    } catch (err) {
      console.error(
        "Error in charge function:",
        err.response || err.message || err
      );

      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text:
          err.response?.data?.message ||
          err.message ||
          "An unexpected error occurred.",
        confirmButtonColor: "#0099FF",
      });
    }
  };

  return (
    <div className="advertising-billing-container">
      <div className="page-title">Advertisement payment</div>
      <p
        style={{
          width: "100%",
          padding: "32px",
          textAlign: "center",
          margin: 0,
        }}
      >
        By paying for your advertisement, it will be reviewed by the admin. Once
        approved, it will be displayed on the website for parents and students
        to see. You will be notified by email once the advertisement goes live.
      </p>
      <div className="main-box">
        <div className="billing-description">
          <div className="description-main">
            <div className="description-head">Advertisement Payment</div>
            <div className="description-body">
              <img
                src={CircleSvg}
                alt="circle-container"
                className="circle-container"
              />
              <div className="description-center">
                <div>
                  {/* <p>For teacher</p> */}
                  <p className="dollar-28">{advancePrice}</p>
                  <p className="per-year">
                    {duration === "12"
                      ? "1 Year"
                      : duration === "1"
                      ? "1 Month"
                      : `${duration} Months`}
                  </p>
                </div>
              </div>
            </div>
            <img
              src="/assets/images/secure-payments.png"
              alt="Secure logo"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div style={{ flex: 6 }}>
          <div className="form">
            <div className="form-control">
              <div className="label">Card holder name</div>
              <TextField
                variant="outlined"
                hiddenLabel
                style={{ margin: "0" }}
                onChange={(e) => setName(e.target.value)}
                placeholder="First and last name"
                error={!!error["name"]}
                value={name}
                fullWidth
                id="billing-name"
              />
            </div>
            <div className="form-control">
              <div className="label">Price</div>
              <TextField
                variant="outlined"
                hiddenLabel
                style={{ margin: "0" }}
                value={
                  duration === 1
                    ? `1 month (${advancePrice})`
                    : duration === 12
                    ? `1 year (${advancePrice})`
                    : `${duration} months (${advancePrice})`
                }
                fullWidth
                id="billing-price"
                InputProps={{ readOnly: true }}
              />
            </div>

            {/* Stripe Card Details Section */}
            <div className="form-control">
              <div className="label">Card details</div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardNumberElement options={{ style: cardElementStyles }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CardExpiryElement options={{ style: cardElementStyles }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CardCvcElement options={{ style: cardElementStyles }} />
                </Grid>
              </Grid>
            </div>

            <div className="form-control">
              <div className="label">Country</div>
              <CountrySelect
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>

            <div className="form-control">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Checkbox
                  onChange={(e) => setAccept(e.target.checked)}
                  value={accept}
                />
                <span className="accept">
                  I accept the&nbsp;
                  <span className="term" onClick={() => navigate("/terms")}>
                    terms and conditions
                  </span>
                </span>
              </div>
            </div>

            <div className="form-control">
              <LoadingButton
                onClick={handleClick}
                loading={loading}
                variant="contained"
                disabled={!accept}
                size="large"
              >
                <span>Make payment</span>
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseBilling;
