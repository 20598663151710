import { useState, useEffect } from "react";
import { CoursesNavbar } from "./Components/Navbar/Navbar";
import { Filter } from "./Components/filter/filter";
import { CourseCard } from "./Components/CourseCard/CourseCardRenew";
import { SearchBars } from "./Components/SearchBars/SearchBars";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./RenewPayments.css"; // Import CSS
import { Box, Button, Typography } from "@mui/material";
import config from "config";
const RenewPayments = () => {
  const [id, setId] = useState("");
  const paramsId = useParams();
  const location = useLocation();
  console.log("hello");

  useEffect(() => {
    if (paramsId) {
      setId(paramsId);
    } else {
      setId(null);
    }
  }, [paramsId]);

  return (
    <Box className="courses-container">
      <Box
        sx={{
          maxWidth: "975px",
          margin: "0px 15px 0 auto",
          padding: "20px",
          display: "block",
          width: "100%",
        }}
      >
        {/* Row Container - Title (Left) & Button (Right) */}
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* Title (Fully Left-Aligned) */}
          <Typography
            sx={{
              fontSize: "2.5rem",
              color: "#000",
              fontWeight: "bold",
              marginLeft: "0px",
            }}
          >
            Renew Course
          </Typography>
        </Box>

        {/* Full-Width Divider */}
        <hr
          style={{
            width: "100%",
            borderTop: "1px solid #ccc",
            marginTop: "10px",
          }}
        />
      </Box>
      {/* Main Content */}
      <Box className="content-container">
        {/* Filter */}
        <Box className="filter-container notop">
          <div className="billing-menu notfixed">
            <Link
              className={`tab${
                location.pathname === "/my-courses"
                  ? " active " + config["class-name"]
                  : ""
              }`}
              to="/my-courses"
            >
              My Courses
            </Link>
            <Link
              className={`tab${
                location.pathname === "/my-courses/billing"
                  ? " active " + config["class-name"]
                  : ""
              }`}
              to="/my-courses/billing"
            >
              My Payments
            </Link>
            <Link
              className={`tab${
                location.pathname === "/my-courses/renew"
                  ? " active " + config["class-name"]
                  : ""
              }`}
              to="/my-courses/renew"
            >
              Renew Course
            </Link>
          </div>
        </Box>

        {/* Courses */}
        <Box className="courses-list">
          <CourseCard idd={id} />
        </Box>
      </Box>
    </Box>
  );
};

export default RenewPayments;
