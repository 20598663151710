import { useContext } from "react";
import { Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { FilterContext } from "../FilterContext/FilterContext";
const types = [
  { key: "Primary (7-11 yrs)", value: "primary-7-11" },
  { key: "Primary (5-11 yrs)", value: "primary-5-11" },
  { key: "Secondary (11-14 yrs)", value: "secondary-11-14" },
  { key: "GCSE (15-16 yrs)", value: "gcse-15-16" },
  { key: "A-Level (5-18 yrs)", value: "a-level-5-18" },
  { key: "Degree (Adults)", value: "degree-adults" },
  { key: "Other", value: "other" },
];

export const Filter = () => {
  const { setSelectedFilters } = useContext(FilterContext);

  const handleFilterChange = (e) => {
    const { value, checked } = e.target;
    setSelectedFilters((prevFilters) => {
      const updatedFilters = checked
        ? [...prevFilters, value]
        : prevFilters.filter((filter) => filter !== value);
      return updatedFilters;
    });
  };
  return (
    <Box
      sx={{
        width: { lg: "250px", md: "100%", sm: "100%", xs: "100%" }, // Set a fixed width for large screens
        maxWidth: "300px", // Prevent it from stretching too much
      }}
    >
      <Box>
        <Typography sx={{ color: "black" }}>Filters</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          color: "black",
        }}
      >
        {types.map((type, index) => (
          <FormControlLabel
            sx={{ "& .MuiFormControlLabel-label": { marginLeft: "10px" } }}
            key={index}
            control={<Checkbox value={type.value} />}
            label={type.key}
            onChange={handleFilterChange}
          />
        ))}
      </Box>
    </Box>
  );
};
