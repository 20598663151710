import React from "react";
import "./HowItWorks.css";

import { ReactComponent as Curve } from "../../assets/images/undercurve.tsx";

import { Typography } from "@mui/material";

const HowItWorks = () => {
  return (
    <>
      {" "}
      <div className="howitworks-container">
        {" "}
        <div className="howitworks-heading-box">
          {" "}
          <div className="page-heading">
            {" "}
            <h1
              sx={{
                color: "primary.main",
              }}
            >
              How It Works
            </h1>{" "}
            <Curve
              style={{
                maxWidth: "100%",
              }}
            />{" "}
          </div>{" "}
        </div>{" "}
        <div className="howitworks-bottom-box">
          {" "}
          <div className="howitworks-row">
            {" "}
            <div className="howitworks-left-col">
              {" "}
              <h4>For Students and Parents</h4>{" "}
              <Typography variant="h2" color="primary">
                {" "}
                Find a Tutor{" "}
              </Typography>{" "}
              <p>
                {" "}
                Our platform makes it simple to connect with the best tutors
                near you for Free. Follow these steps:{" "}
              </p>{" "}
              <ol>
                {" "}
                <li>
                  {" "}
                  <strong>Search:</strong> Use the search bar to enter your
                  location, level of study (e.g., GCSE, A-Level, etc.), and
                  specific requirements.{" "}
                </li>{" "}
                <li>
                  {" "}
                  <strong>Browse:</strong> Review detailed tutor profiles that
                  include qualifications, experience, availability, and hourly
                  rates.{" "}
                </li>{" "}
                <li>
                  {" "}
                  <strong>Filter Results:</strong> Narrow down your choices with
                  advanced filters, such as online/offline options or specific
                  specialisations (e.g., algebra, calculus).{" "}
                </li>{" "}
              </ol>{" "}
              <Typography variant="h2" color="primary">
                {" "}
                Connect with a Tutor{" "}
              </Typography>{" "}
              <p>Once you’ve found the ideal tutor:</p>{" "}
              <ol>
                {" "}
                <li>
                  {" "}
                  <strong>Contact:</strong> Send a message directly through the
                  platform to discuss your needs and availability.{" "}
                </li>{" "}
                <li>
                  {" "}
                  <strong>Book a Session:</strong> Agree on a time and place for
                  your first session – whether online or in person – completely
                  free.{" "}
                </li>{" "}
              </ol>{" "}
            </div>{" "}
            <div className="howitworks-right-col">
              {" "}
              <h4>For Tutors</h4>{" "}
              <Typography variant="h2" color="primary">
                {" "}
                Create Your Profile{" "}
              </Typography>{" "}
              <p>Join Tutors Directory and showcase your skills by:</p>{" "}
              <ol>
                {" "}
                <li>
                  {" "}
                  <strong>Signing Up:</strong> Complete a quick registration
                  process.{" "}
                </li>{" "}
                <li>
                  {" "}
                  <strong>Building Your Profile:</strong> Add your teaching
                  qualifications, experience, areas of expertise, hourly rates,
                  and availability. You can also add your own social links as
                  well (website, FB page etc.).{" "}
                </li>{" "}
                <li>
                  {" "}
                  <strong>Affordable Pricing:</strong> It only costs you £2 per
                  month ! That’s cheaper than a cup of coffee or tea.{" "}
                </li>{" "}
              </ol>{" "}
              <Typography variant="h2" color="primary">
                {" "}
                Market your course/classes{" "}
              </Typography>{" "}
              <p>
                {" "}
                <strong>Courses to Run:</strong> You can advertise any courses
                you offer, such as{" "}
                <ul>
                  {" "}
                  <li>
                    {" "}
                    <Typography variant="p" color="primary">
                      {" "}
                      <strong>Masterclasses</strong>{" "}
                    </Typography>{" "}
                  </li>{" "}
                  <li>
                    {" "}
                    <Typography variant="p" color="primary">
                      {" "}
                      <strong> Group tutoring sessions</strong>{" "}
                    </Typography>{" "}
                  </li>{" "}
                  <li>
                    {" "}
                    <Typography variant="p" color="primary">
                      {" "}
                      <strong> Workshops</strong>{" "}
                    </Typography>{" "}
                  </li>{" "}
                </ul>{" "}
              </p>{" "}
              <h4>We Handle the Advertising & Marketing</h4>{" "}
              <ul>
                {" "}
                <li>
                  {" "}
                  Once your profile is live, Tutors Directory handles all the
                  advertising and marketing to ensure your profile gets noticed
                  through targeted campaigns.{" "}
                </li>{" "}
                <li>
                  {" "}
                  Our large, active Facebook group encourages parents to use the
                  directories, driving parent traffic to the platform.{" "}
                </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </>
  );
};

export default HowItWorks;
