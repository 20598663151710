import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Typography, Button, Modal, Link, Grid } from "@mui/material";
import axios from "axios";
import { FilterContext } from "../FilterContext/FilterContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCopy,
  faCalendar,
  faMoneyBill,
  faPhone,
  faEdit,
  faMapMarker,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Message from "../../../Dashboard/Components/Messages/Message";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import Snackbar from "@mui/material/Snackbar";
import Swal from "sweetalert2";

const levels = [
  {
    value: "primary-7-11",
    key: "Primary (7/11+)",
  },
  {
    value: "primary-5-11",
    key: "Primary (5-11 yrs)",
  },
  {
    value: "secondary-11-14",
    key: "Seconday (11-14 yrs)",
  },
  {
    value: "gcse-15-16",
    key: "GCSE (15-16 yrs)",
  },
  {
    value: "a-level-5-18",
    key: "A-level (15-18 yrs)",
  },
  {
    value: "degree-adults",
    key: "Degree/Adults",
  },
  {
    value: "other",
    key: "Other",
  },
];

export const CourseCard = (props) => {
  const { idd } = props;
  const [courses, setCourses] = useState([]);
  const [courseModalOpen, setCourseModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const searchName = "";
  const paymentMethod = "all";
  const searchLocation = "";
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  // Add a console log to verify if the context values are coming through

  let userId;
  try {
    const persistedData = localStorage.getItem("persist:root");
    const userData = JSON.parse(persistedData);
    if (userData.userId && typeof userData.userId === "string") {
      userData.userId = userData.userId.replace(/\\/g, "");
    }
    userId = userData?.userId;
  } catch (error) {
    console.error("Error parsing localStorage data:", error);
    userId = null;
  }

  const userIdnew = userId.replace(/"/g, ""); // Remove extra quotes;;
  // useSelector((state) => state.profile.user.user);

  const handleClick = () => {
    setSnackBarOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };
  useEffect(() => {
    // Simulate URL search string
    if (idd.id) {
      setId(idd.id);
    } else {
      setId(null);
    }
  }, [idd.id]);

  const fetchCourses = async () => {
    console.log("userIdnew value:", userIdnew); // Log to verify userIdnew
    console.log("userId value:", id); // Log to verify userIdnew

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/courses/coursesUser/${userIdnew}/false`
      );
      setCourses(response.data);
      console.log("response.data", response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.warn(
          "No courses found for this user. Setting courses to empty."
        );
        setCourses([]); // set an empty array or any default state
      } else {
        console.error("Error fetching courses:", error);
      }
    }
  };

  const handleDeleteModal = async (e, id) => {
    e?.stopPropagation();

    console.log("Attempting to delete course with ID:", id);

    // Show confirmation popup before deleting
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action will delete the course permanently!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        console.log("User confirmed deletion. Sending delete request...");
        const response = await axios.delete(
          `${process.env.REACT_APP_SERVER}/api/courses/delete/${id}`
        );

        console.log("Delete response:", response);

        if (response.status === 200) {
          console.log("Course deleted successfully.");
          Swal.fire("Deleted!", "The course has been deleted.", "success");
          fetchCourses(); // Refresh the course list after deletion
        } else {
          console.error("Unexpected response status:", response.status);
          Swal.fire(
            "Error!",
            "Failed to delete the course. Try again.",
            "error"
          );
        }
      } catch (error) {
        console.error("Error while deleting course:", error);
        Swal.fire("Error!", "Something went wrong. Please try again.", "error");
      }
    } else {
      console.log("User canceled deletion.");
    }
  };

  const handleCourseModalOpen = (course) => {
    setSelectedCourse(course);
    navigate(`/course-detail/${course._id}`);
  };

  const navigteEdit = (course) => {
    console.log(course);

    setSelectedCourse(course);
    navigate(`/course/edit/${course._id}`);
  };

  const handleCourseModalClose = () => {
    setCourseModalOpen(false);
    setSelectedCourse(null);
  };

  const handleMessageModalOpen = () => {
    setMessageModalOpen(true);
  };

  const handleMessageModalClose = () => {
    setMessageModalOpen(false);
  };

  const handleShareModalOpen = (course) => {
    setSelectedCourse(course);
    setShareModalOpen(true);
  };

  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };

  const copyToClipboard = (course) => {
    const courseId = course._id;
    const url = `${process.env.REACT_APP_URL}/courses/${courseId}`;
    navigator.clipboard.writeText(url).then(
      function () {
        setSnackBarOpen(true);
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const filteredCourses = courses.filter((course) => {
    const price = parseFloat(
      (course?.coursePrice ?? "").replace(/[^0-9.-]+/g, "")
    );
    const showAllPaymentMethods =
      paymentMethod === "" || paymentMethod === "all";
    return (
      (searchName === "" ||
        course.name.toLowerCase().includes(searchName.toLowerCase())) &&
      (searchLocation === "" ||
        course.location.toLowerCase().includes(searchLocation.toLowerCase())) &&
      (showAllPaymentMethods ||
        (paymentMethod === "paid" && price > 0) ||
        (paymentMethod === "free" && (isNaN(price) || price === 0)))
    );
  });

  const handleFacebookShare = (course) => {
    const courseId = course._id;
    const url = `${process.env.REACT_APP_URL}/courses/${courseId}`;
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  const handleWhatsAppShare = (course) => {
    const courseId = course._id;
    const url = `${process.env.REACT_APP_URL}/courses/${courseId}`;
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    if (id) {
      fetchCourses();
    }
  }, [id]);

  useEffect(() => {
    if (id && courses.length > 0) {
      const course = courses.find((c) => c._id === id);
      if (course) {
        setSelectedCourse(course);
        setCourseModalOpen(true); // Open the modal for the course
      }
    }
  }, [courses, id]);

  const navigate = useNavigate();

  const pdfUrl = selectedCourse?.file
    ? `${process.env.REACT_APP_SERVER}/${selectedCourse.file}`
    : `${process.env.REACT_APP_SERVER}/default-file.pdf`;
  function onError(e) {
    // e.target.src = placeholder;
  }

  return (
    <div style={{ paddingTop: "20px", width: "100%", margin: "0px auto" }}>
      <Grid
        container
        spacing={2} // Adjusted the spacing to make the grid more compact
        style={{ marginLeft: "0", marginRight: "0" }} // Reduced the left and right margins for a more balanced layout
      >
        {filteredCourses.map((course, index) => (
          <Grid
            style={{
              marginTop: "20px", // Reduced the top margin to make the grid more compact
              padding: "10px",
            }}
            sx={{
              width: { sm: "100%", md: "30%" },
              height: { lg: "100%", md: "100%" },
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              overflow: "hidden",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "scale(1.02)",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
              },
              cursor: "pointer",
              margin: "10px",
            }}
          >
            <Box>
              <img
                src={`${process.env.REACT_APP_SERVER}/${
                  course.image ?? "default-image.jpg"
                }`}
                alt="courseimage"
                onError={(e) => {
                  e.target.src = "/assets/course-place.png";
                }}
                style={{
                  height: "209px",
                  minHeight: "209px",
                  width: "100%",
                }}
              />
            </Box>
            <Box sx={{ padding: "9px" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "1000", marginBottom: "10px" }}
              >
                {course.name}
                <br />
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  {course?.level && (
                    <Typography textTransform={"capitalize"} variant="h6">
                      <span style={{ color: "black" }}>Level: </span>
                      <span style={{ fontWeight: "bolder" }}>
                        {levels.find((level) => level.value === course.level)
                          ?.key || "Unknown"}
                      </span>
                    </Typography>
                  )}

                  {course?.types && (
                    <Typography variant="h6">
                      <span style={{ color: "black" }}>Location: </span>
                      <span style={{ fontWeight: "bolder" }}>
                        {course.types[0] &&
                          course.types[0].charAt(0).toUpperCase() +
                            course.types[0].slice(1)}

                        {course.types[0] && course.types[1] && " / "}

                        {course.types[1] &&
                          course.types[1].charAt(0).toUpperCase() +
                            course.types[1].slice(1)}
                      </span>
                    </Typography>
                  )}

                  {course?.isOngoing && (
                    <Typography variant="h6">
                      <span style={{ color: "black" }}>Date: </span>
                      <span style={{ fontWeight: "bolder" }}>Anytime</span>
                    </Typography>
                  )}

                  {!course?.isOngoing && (
                    <Typography variant="h6">
                      <span style={{ color: "black" }}>Date: </span>
                      <span style={{ fontWeight: "bolder" }}>
                        {new Date(
                          courses?.[0]?.date[0]?.split("T")?.[0]
                        ).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}{" "}
                        -{" "}
                        {new Date(
                          courses?.[0]?.date[1]?.split("T")?.[0]
                        ).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                      </span>
                    </Typography>
                  )}

                  <Typography variant="h6">
                    <span style={{ color: "black" }}>Price: </span>
                    <span style={{ color: "#5bb450", fontWeight: "bolder" }}>
                      {course?.coursePrice && course.coursePrice > 0
                        ? `£${course.coursePrice}`
                        : "FREE"}
                    </span>
                  </Typography>
                  <br />
                  <Typography variant="h6">
                    <span style={{ color: "black" }}>Days left: </span>
                    <span style={{ color: "red", fontWeight: "bolder" }}>
                      {(() => {
                        if (!course?.expirationDate) return "N/A"; // Handle missing data

                        const expirationDate = new Date(course.expirationDate);
                        const today = new Date();

                        // Calculate the difference in milliseconds and convert to days
                        const timeDiff = expirationDate - today;
                        const daysLeft = Math.ceil(
                          timeDiff / (1000 * 60 * 60 * 24)
                        );

                        return daysLeft > 0 ? `${daysLeft}` : "Expired";
                      })()}
                    </span>
                  </Typography>

                  <Typography variant="h6">
                    <span style={{ color: "black" }}>Status: </span>
                    <span
                      style={{
                        color: course?.isApproved ? "green" : "red",
                        fontWeight: "bolder",
                      }}
                    >
                      {course?.isApproved ? "Live" : "Pending approval"}
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: "15px",
                  marginTop: "20px",
                }}
              >
                {/* Edit button is conditionally rendered */}
                {userIdnew == course?.userId && (
                  <>
                    {/* Delete Button */}
                    <Button
                      sx={{
                        backgroundColor: "red",
                        color: "#fff",
                        padding: "10px 20px",
                        borderRadius: "28px",
                        marginTop: "0",
                        marginLeft: "0px", // Adds space between buttons
                        width: { xs: "30vw", sm: "20vw", md: "7vw", lg: "8vw" },
                        "&:hover": {
                          backgroundColor: "#b30000",
                        },
                      }}
                      onClick={(e) => handleDeleteModal(e, course._id)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          marginRight: "0px",
                          padding: "5.4px 0",
                          marginLeft: 0,
                        }}
                      />
                    </Button>

                    {/* Edit Button */}
                    <Button
                      sx={{
                        backgroundColor: "#712d93",
                        color: "#fff",
                        padding: "10px 20px",
                        borderRadius: "28px",
                        marginTop: "0",
                        width: { xs: "30vw", sm: "20vw", md: "7vw", lg: "8vw" },
                        "&:hover": {
                          backgroundColor: "#609BDF",
                        },
                      }}
                      onClick={() => navigteEdit(course)}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ marginRight: "5px" }}
                      />
                      Edit
                    </Button>
                  </>
                )}

                {/* "Read More" button always stays on the right */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#712d93",
                      color: "#fff",
                      padding: "10px 20px",
                      borderRadius: "28px",
                      "&:hover": {
                        backgroundColor: "#609BDF",
                      },
                    }}
                    onClick={() => handleCourseModalOpen(course)}
                  >
                    Read More
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Link Copied to Clickboard"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Modal
        open={courseModalOpen}
        onClose={handleCourseModalClose}
        aria-labelledby="course-modal-title"
        aria-describedby="course-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "80%", md: "70%", lg: "50%", xs: "90%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            height: { sm: "80%", md: "55%", lg: "55%", xs: "90%" },
            overflowY: { sm: "auto", md: "auto", lg: "auto", xs: "auto" },
          }}
        >
          {selectedCourse && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography id="course-modal-title" variant="h4" sx={{ mb: 2 }}>
                  {selectedCourse.name} - {selectedCourse.level} -{" "}
                  {selectedCourse.types}
                </Typography>
                <Typography
                  onClick={handleCourseModalClose}
                  sx={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                  },
                }}
              >
                <Box>
                  <img
                    src={`${process.env.REACT_APP_SERVER}/${
                      selectedCourse.image ?? "default-image.jpg"
                    }`}
                    alt="courseimage"
                    style={{
                      height: "250px",
                      width: "50%",
                      objectFit: "cover",
                      marginBottom: "20px",
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    id="course-modal-description"
                    variant="body1"
                    sx={{ mb: 2, fontSize: "1.4rem" }}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    By {selectedCourse.by}
                  </Typography>
                  <Typography
                    id="course-modal-description"
                    variant="body1"
                    sx={{ mb: 2, fontSize: "1.4rem" }}
                  >
                    <FontAwesomeIcon
                      icon={faCalendar}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    {selectedCourse.date}
                  </Typography>
                  <Typography
                    id="course-modal-description"
                    variant="body1"
                    sx={{ mb: 2, fontSize: "1.4rem" }}
                  >
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    {selectedCourse.coursePrice}
                  </Typography>
                  <Typography
                    id="course-modal-description"
                    variant="body1"
                    sx={{ mb: 2, fontSize: "1.4rem" }}
                  >
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    {selectedCourse.telephone}
                  </Typography>
                  <Typography
                    id="course-modal-description"
                    variant="body1"
                    sx={{ mb: 2, fontSize: "1.4rem" }}
                  >
                    <FontAwesomeIcon
                      icon={faMapMarker}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    {selectedCourse.location}
                  </Typography>
                  <Typography
                    id="course-modal-description"
                    variant="body1"
                    sx={{ mb: 2, fontSize: "1.4rem" }}
                  >
                    {selectedCourse.description}
                  </Typography>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Button
                      sx={{
                        backgroundColor: "#712d93",
                        color: "#fff",
                        padding: "10px 20px",
                        borderRadius: "28px",
                        "&:hover": {
                          backgroundColor: "#609BDF",
                        },
                      }}
                      onClick={handleMessageModalOpen}
                    >
                      Contact Us
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: "#712d93",
                        color: "#fff",
                        padding: "10px 20px",
                        borderRadius: "28px",
                        "&:hover": {
                          backgroundColor: "#609BDF",
                        },
                      }}
                      href={pdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download PDF
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={messageModalOpen}
        onClose={handleMessageModalClose}
        aria-labelledby="message-modal-title"
        aria-describedby="message-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { sm: "80%", md: "50%", lg: "40%", xs: "90%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            height: { sm: "80%", md: "70%", lg: "60%", xs: "90%" },
            overflowY: { sm: "auto", md: "auto", lg: "auto", xs: "auto" },
          }}
        >
          <Typography
            onClick={handleMessageModalClose}
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Typography>
          <Message />
        </Box>
      </Modal>

      <Modal
        open={shareModalOpen}
        onClose={handleShareModalClose}
        aria-labelledby="share-modal-title"
        aria-describedby="share-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "40%", lg: "30%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {selectedCourse && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  cursor: "pointer",
                  mb: 2,
                }}
                onClick={handleShareModalClose}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Box>
              <Typography
                id="share-modal-title"
                variant="h6"
                sx={{ mb: 2, fontWeight: "bold", fontSize: "2rem" }}
              >
                Share This Course
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  onClick={() => handleWhatsAppShare(selectedCourse)}
                  style={{
                    padding: 0,
                    fontSize: "2rem",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <WhatsAppIcon sx={{ fontSize: "4rem" }} />
                </Button>

                <Button onClick={() => handleFacebookShare(selectedCourse)}>
                  <FacebookSharpIcon sx={{ fontSize: "4rem" }} />
                </Button>

                <Button onClick={() => copyToClipboard(selectedCourse)}>
                  <FontAwesomeIcon icon={faCopy} style={{ fontSize: "4rem" }} />
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};
