import React, { useEffect, useState } from "react";
import "./Billing.css";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_URL, createAxiosInstance } from "../../api";
import SweetAlert from "sweetalert2";
import BillingHistory from "./Components/BillingHistory/BillingHistory";
import SavedCards from "./Components/SavedCards/SavedCards";
import config from "config";

import {
  Badge,
  Box,
  Button,
  Link as MuiLink,
  Menu,
  MenuItem,
} from "@mui/material";
const Billing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isGuest } = useSelector((state) => state.main);
  const { _id, email } = useSelector((state) => state.profile.user);

  const [isLive, setIsLive] = useState("");
  const [nextBillingDate, setNextBillingDate] = useState("");
  const [membership, setMembership] = useState("");
  const [price, setPrice] = useState("");
  const [daysLeft, setDaysLeft] = useState("");
  const [description, setDescription] = useState("");
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(""); // New state for cancellation status
  const formattedBilling = nextBillingDate
    ? new Date(nextBillingDate * 1000).toLocaleDateString("en-UK", {
        weekday: "long", // Optional: day of the week (e.g., Monday)
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : "N/A"; // If nextBillingDate is not available

  const userId = useSelector((state) => state.profile.user.user);
  console.log(userId);

  useEffect(() => {
    const fetchBillingData = async () => {
      try {
        setLoading(true); // Set loading state
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.get(
          `${API_URL}/api/billings/status/${userId}`
        );

        // Destructure response data
        const {
          status,
          nextBilling,
          membership,
          price,
          description,
          cancel_at_period_end,
          daysLeft,
        } = response.data;

        console.log(response.data);

        // Set subscription status
        if (status === "active") {
          setIsLive("active");
        } else if (status === "paused") {
          setIsLive("paused");
        } else if (status === "past_due") {
          setIsLive("past_due");
        } else if (status === "canceled") {
          setIsLive("canceled");
        } else {
          setIsLive("inactive");
        }

        // Set other states based on fetched data
        setNextBillingDate(nextBilling || "N/A");
        setPrice(price || "N/A");
        setDaysLeft(daysLeft || "N/A");
        setDescription(description || "N/A");
        setCancelAtPeriodEnd(cancel_at_period_end || false);

        // Determine membership based on price
        if (price === 2) {
          setMembership("Monthly Subscription");
        } else if (price === 2499) {
          setMembership("Yearly Subscription");
        } else {
          setMembership(membership || "N/A");
        }
      } catch (err) {
        console.error("Error fetching billing data:", err);
      } finally {
        setLoading(false); // Stop loading after fetching is complete
      }
    };

    if (userId) {
      fetchBillingData(); // Call the function directly instead of using setTimeout
    }
  }, [userId]);

  const handlePauseSubscription = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "This will pause your subscription!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, pause it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const axiosInstance = createAxiosInstance();
          await axiosInstance.post(`${API_URL}/api/billings/pause/${userId}`);

          SweetAlert.fire(
            "Paused",
            "Your subscription has been paused.",
            "success"
          ).then(() => {
            setIsLive("paused");
            window.location.reload(); // Reload the page after the alert is confirmed
          });
        } catch (error) {
          SweetAlert.fire(
            "Error",
            "There was an issue pausing your subscription.",
            "error"
          );
        }
      }
    });
  };

  const handleResumeSubscription = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "This will resume your subscription!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, resume it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const axiosInstance = createAxiosInstance();
          const response = await axiosInstance.post(
            `${API_URL}/api/billings/resumes/${userId}`
          );

          // If the response is successful, show success message
          SweetAlert.fire(
            "Resumed",
            "Your subscription has been resumed.",
            "success"
          ).then(() => {
            window.location.reload(); // Reload the page after the alert is confirmed
          });
        } catch (error) {
          // Log the error details to the console for debugging
          console.error("Error resuming subscription:", error);

          // Check if error response exists and handle accordingly
          if (error.response) {
            // If the error has a response (i.e., status code other than 2xx)
            SweetAlert.fire(
              "Error",
              `There was an issue resuming your subscription: ${
                error.response.data.message || error.message
              }`,
              "error"
            );
          } else {
            // If the error does not have a response, display a generic error message
            SweetAlert.fire(
              "Error",
              "There was an unexpected error while resuming your subscription.",
              "error"
            );
          }
        }
      }
    });
  };

  const handleCancelSubscription = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "This will cancel your subscription, and you will no longer be billed.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, cancel it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const axiosInstance = createAxiosInstance();
          await axiosInstance.delete(
            `${API_URL}/api/billings/cancel/${userId}`
          );
          SweetAlert.fire(
            "Canceled",
            "Your subscription has been cancelled. It will remain active until the end of the billing period.",
            "success"
          ).then(() => {
            setCancelAtPeriodEnd(true); // Update the state if cancellation is confirmed
            // window.location.reload(); // Reload the page after the alert is confirmed
          });
        } catch (error) {
          SweetAlert.fire(
            "Error",
            "There was an issue canceling your subscription.",
            "error"
          );
        }
      }
    });
  };

  return (
    <div className="billing-container">
      <div className="billing-menu">
        <Link
          className={`tab${
            location.pathname === "/billing"
              ? " active " + config["class-name"]
              : ""
          }`}
          to="/billing"
        >
          My Plan
        </Link>
        <Link
          className={`tab${
            location.pathname === "/billing/history"
              ? " active " + config["class-name"]
              : ""
          }`}
          to="/billing/history"
        >
          History
        </Link>
        <Link
          className={`tab${
            location.pathname === "/billing/saved-cards"
              ? " active " + config["class-name"]
              : ""
          }`}
          to="/billing/saved-cards"
        >
          Saved Cards
        </Link>
        <Link
          className={`tab${
            location.pathname === "/billing/change-plan"
              ? " active " + config["class-name"]
              : ""
          }`}
          to="/billing/change-plan"
        >
          Change Plan
        </Link>
      </div>

      <div className="billing-content">
        {location.pathname === "/billing" && (
          <div className="my-plan-section">
            <h1 className="page-title">My Plan</h1>
            <div className="plan-status">
              <div>
                <div className="billing-info">
                  <h3>Subscription Status</h3>
                  <p
                    className={
                      isLive === "active"
                        ? "active"
                        : isLive === "paused"
                        ? "paused"
                        : isLive === "past_due"
                        ? "past-due"
                        : isLive === "canceled"
                        ? "canceled"
                        : "inactive"
                    }
                  >
                    Status:{" "}
                    {isLive === "paused"
                      ? "Paused"
                      : isLive === "past_due"
                      ? "Past Due"
                      : isLive === "canceled"
                      ? "Cancelled"
                      : isLive}
                  </p>
                  <p>Membership: {membership}</p>
                  <p>Price: £{price}</p>

                  {cancelAtPeriodEnd === "false" && isLive === "active" && (
                    <p>Next Billing Date: {formattedBilling}</p>
                  )}

                  {cancelAtPeriodEnd === "true" ||
                    (cancelAtPeriodEnd === true && (
                      <p className="cancel-note">
                        Your subscription has been canceled. <br />
                        It will remain active until the end of the billing
                        period.
                      </p>
                    ))}

                  {isLive === "paused" && daysLeft > 0 && (
                    <p>
                      Your subscription is paused. <br /> {daysLeft} days
                      remaining in the billing cycle.
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Action buttons */}
            {isLive === "active" && cancelAtPeriodEnd === "false" && (
              <div className="action-buttons">
                <Button
                  variant="contained"
                  color="primary"
                  className="cancel-btn"
                  onClick={handleCancelSubscription}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="pause-btn"
                  onClick={handlePauseSubscription}
                >
                  Pause
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="link-btn"
                  onClick={() => navigate("/billing/change-plan")}
                >
                  Change Plan
                </Button>
              </div>
            )}

            {isLive === "paused" && (
              <div className="action-buttons">
                <Button
                  variant="contained"
                  color="primary"
                  className="cancel-btn"
                  onClick={handleCancelSubscription}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="resume-btn"
                  onClick={handleResumeSubscription}
                >
                  Resume
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="link-btn"
                  onClick={() => navigate("/billing/change-plan")}
                >
                  Change Plan
                </Button>
              </div>
            )}

            {isLive === "canceled" && (
              <div className="action-buttons">
                <Button
                  variant="contained"
                  color="primary"
                  className="link-btn"
                  onClick={() => navigate("/billing/change-plan")}
                >
                  Reactivate
                </Button>
              </div>
            )}

            {isLive === "past_due" && (
              <div className="action-buttons">
                <Button
                  variant="contained"
                  color="primary"
                  className="link-btn"
                  onClick={() => navigate("/billing/change-plan")}
                >
                  Reactivate
                </Button>
              </div>
            )}

            {isLive === "inactive" && (
              <div className="action-buttons">
                <Button
                  variant="contained"
                  color="primary"
                  className="link-btn"
                  onClick={() => navigate("/billing/change-plan")}
                >
                  Reactivate
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Billing;
