import React, { useEffect, useMemo, useState } from "react";
import "./Search.css";
import SubjectSearch from "./Components/SubjectSearch"; // Import the new component
import FilterBox from "./Components/FilterBox";
import ResultBox from "./Components/ResultBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchTutors, setFilters } from "../../store/search";

const Search = () => {
  const { filters } = useSelector((state) => state.search);
  const {
    level,
    gender,
    isOnline,
    price,
    distance,
    exam,
    latitude,
    longitude,
  } = filters;
  const [loading, setLoading] = useState(true);
  // const mathLevel = filters.level;
  const { subject, level: selectedLevel } = useSelector(
    (state) => state.search.filters
  );

  const setSubject = (value) => dispatch(setFilters({ subject: value }));
  const setSelectedLevel = (value) => dispatch(setFilters({ level: value }));
  const dispatch = useDispatch();
  // const filterParams = useMemo(() => {
  //   return {
  //     price: '15,100',
  //     level: mathLevel ?? undefined
  //   }
  // }, []);

  useEffect(() => {
    let filterParams = {};

    if (subject) filterParams.subject = subject; // Add subject
    if (level || selectedLevel) filterParams.level = level || selectedLevel;
    if (isOnline > -1) filterParams.isOnline = isOnline;
    if (isOnline === 0) {
      filterParams.distance = distance;
      filterParams.latitude = latitude;
      filterParams.longitude = longitude;
    }
    if (gender > -1) filterParams.gender = gender;
    filterParams.price = price.join(",");
    if (exam) filterParams.exam = exam;

    console.log("Dispatching fetchTutors with:", filterParams); // Log request params

    dispatch(fetchTutors({ filterParams, setLoading }))
      .unwrap()
      .then((data) => {
        console.log("Tutors fetched successfully:", data);
      })
      .catch((error) => {
        console.error("Error fetching tutors:", error);
      });

    // Add dependencies to ensure re-fetching when filters change
  }, [
    dispatch,
    subject,
    selectedLevel,
    level,
    isOnline,
    distance,
    latitude,
    longitude,
    gender,
    price,
    exam,
  ]);

  return (
    <div className="search-container">
      <div className="search-left">
        <FilterBox setLoading={setLoading} />
      </div>

      <div className="search-right">
        <SubjectSearch
          setSubject={setSubject}
          setSelectedLevel={setSelectedLevel}
        />
        <ResultBox loading={loading} />
      </div>
    </div>
  );
};

export default Search;
