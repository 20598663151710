import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as MDLogo } from "../../assets/math-logo.svg";
import { ReactComponent as EDLogo } from "../../assets/english-logo.svg";
import { ReactComponent as TDLogo } from "../../assets/logos/Td-logo.svg";
import { ReactComponent as SDLogo } from "../../assets/science-logo.svg";
import "./navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/main";
import { initProfile } from "../../store/setProfile";
import { API_URL, createAxiosInstance } from "../../api";

import config from "config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Link as MuiLink,
  Menu,
  MenuItem,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import sd from "../../assets/sciencedirectory-logo/sciencedirectory-logo-icon.png";
import ed from "../../assets/englishdirectory-logo/englishdirectory-20240318-logo-icon.png";
import md from "../../assets/logos/md-logo-icon.png";
import td from "../../assets/logos/Td-logo.svg";
const Navbar = () => {
  let Logo = MDLogo;
  if (config["class-name"] === "english") {
    Logo = EDLogo;
  }
  if (config["class-name"] === "science") {
    Logo = SDLogo;
  }
  if (config["class-name"] === "tutors") {
    Logo = TDLogo;
  }
  const parentsSignUpStyle = {
    variant: "outlined",
    color: "secondary",
  };

  if (config["class-name"] === "math") {
    parentsSignUpStyle.color = "success";
    parentsSignUpStyle.variant = "contained";
  }

  const dispatch = useDispatch();
  const user = useSelector((state) => state.main);
  const isAuth = useSelector((state) => !!state.main.userId);
  const { firstName, lastName, isGuest } = useSelector((state) => state.main);
  const { avatar: tutorAvatar, gender } = useSelector(
    (state) => state.profile.user
  );
  const { _id } = useSelector((state) => state.profile.guestInformation);
  const { avatar: guestAvatar } = useSelector(
    (state) => state.profile.guestInformation
  );

  const unreadMessageCount = useSelector(
    (state) =>
      state.profile?.messages?.filter((message) => !message.isRead).length
  );
  const fullName = isAuth
    ? `${firstName.charAt(0).toUpperCase() + firstName.slice(1)} ${
        lastName.length > 0 ? lastName.charAt(0).toUpperCase() + "." : ""
      }`
    : "";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickOtherDirectory = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const maleAvatar = "/assets/image/user/male.png";
  const femaleAvatar = "/assets/image/user/female.png";
  const userAvatar = "/assets/image/user/User-avatar.svg.png";
  const avatar =
    isGuest && !guestAvatar?.includes("temp") ? guestAvatar : tutorAvatar;
  const defaultAvatar =
    gender === 1 ? femaleAvatar : gender === 0 ? maleAvatar : userAvatar;
  const [menuOpen, setMenuOpen] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const profileMenuRef = useRef(null);

  const sixtyDaysLater = user.createdAt + 5184000000;
  const currentDate = Date.now();
  const isExpired = sixtyDaysLater < currentDate;

  const navigate = useNavigate();
  const location = useLocation();
  const handleFindTutor = () => {
    if (location.pathname === "/search") {
      window.location.reload();
    } else {
      navigate("/search");
    }
  };
  const [billingStatus, setBillingStatus] = useState("false"); // Local state for billing status
  //const userId = useSelector((state) => state.profile.user.user);
  let userId;
  try {
    const persistedData = localStorage.getItem("persist:root");
    const userData = JSON.parse(persistedData);
    if (userData.userId && typeof userData.userId === "string") {
      userData.userId = userData.userId.replace(/\\/g, "");
    }
    userId = userData?.userId;
  } catch (error) {
    console.error("Error parsing localStorage data:", error);
    userId = null;
  }

  userId = userId.replace(/"/g, ""); // Remove extra quotes;;
  console.log("payment status is:", billingStatus);

  useEffect(() => {
    // Handle checking the payment status
    const checkPaymentStatus = async () => {
      if (!userId) {
        console.log("User ID is undefined or invalid. Skipping payment check.");
        return; // Exit the function early if the userId is not valid
      }

      try {
        console.log("Checking payment status for user:", userId);

        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.post(
          `${API_URL}/api/billings/hasPaid/${userId}`
        );

        console.log("Payment status response:", response.data);

        if (response.data && response.data.paid) {
          console.log("User has:", response.data.paid);

          setBillingStatus(response.data.paid);
        } else {
          console.log("User has not paid.");

          setBillingStatus("false");
        }
      } catch (error) {
        console.error(
          "Error checking payment status:",
          error.response?.data?.message || error.message
        );
        setBillingStatus("false");
      }
    };

    checkPaymentStatus();

    // Handle event listener for clicking outside the profile menu
    const handleOutsideClick = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setShowProfileMenu(false);
      }
    };

    // Attach event listener to document body
    document.body.addEventListener("click", handleOutsideClick);

    // Cleanup event listener on component unmount
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [userId]); // Re-run the effect when `userId` changes

  const handleClick = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const logoutHandle = () => {
    dispatch(logout());
    dispatch(initProfile());
  };

  const linkStyle = {
    textDecoration: "none",
    color: "inherit",
  };

  return (
    <div className="navbar" id="navbar">
      <div className="logo" id="logo">
        <Link to="/">
          <Logo style={{ height: "75px" }} />
        </Link>
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <ul className="nav-container">
          <li className={"hidden-md"}>
            <Link className="nav-item link" to="/">
              Home
            </Link>
          </li>
          <li className={"hidden-md"}>
            <Link className="nav-item link" to="/howitworks">
              How it works
            </Link>
          </li>

          <li className={"hidden-md"}>
            <Link className="nav-item link" to="/courses">
              Courses/classes
            </Link>
          </li>

          {/* <li className={'hidden-md'}>
          <span className="nav-item link" style={{cursor: "pointer"}} onClick={() => {
            handleFindTutor()
          }}>
            Home
          </span>
          </li> */}
          <li>
            <span
              className="nav-item link"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleFindTutor();
              }}
            >
              {config["navbar-link"]}
            </span>
          </li>
          <li className={"hidden-md"}>
            <Link className="nav-item link" to="/contact">
              Contact us
            </Link>
          </li>
          {isAuth ? (
            <li
              className="profile explore-more"
              onClick={handleClick}
              ref={profileMenuRef}
            >
              <span className="name">
                <strong>Nicholas teacher</strong>
              </span>
              <Badge
                className="avatar-bg"
                badgeContent={
                  unreadMessageCount > 0 ? unreadMessageCount : null
                }
                color="error"
                overlap="circular"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div
                  className="avatar"
                  style={{
                    backgroundImage: `url(${
                      avatar ? `${API_URL}${avatar}` : defaultAvatar
                    })`,
                    display: "inline-block",
                    justifyContent: "flex-start",
                    border: unreadMessageCount ? "2px solid #f00" : "none",
                    width: "40px", // Adjust size as needed
                    height: "40px", // Adjust size as needed
                    borderRadius: "50%", // Ensure circular avatar
                  }}
                ></div>
              </Badge>

              {showProfileMenu && (
                <div className="profile-menu">
                  <ul>
                    <li>
                      <Link to="/dashboard" className="link">
                        {fullName}
                      </Link>
                    </li>
                    <hr />
                    <li>
                      <Link to="/dashboard" className="link">
                        Dashboard
                      </Link>
                    </li>
                    {isAuth && !isGuest && (
                      <li>
                        <Link
                          to="/profile?step=0"
                          className={!_id ? "link red" : "link"}
                        >
                          {!_id ? "Create Tutor profile" : "Tutor profile"}
                        </Link>
                      </li>
                    )}

                    {isAuth && !isGuest && (
                      <li>
                        <Link to="/my-courses" className="link">
                          Add/Edit Course/Classes
                        </Link>
                      </li>
                    )}

                    <li>
                      <Link to="/dashboard?tab=message" className="link">
                        {unreadMessageCount > 0 ? (
                          <Badge
                            badgeContent={unreadMessageCount}
                            color="error"
                          >
                            Messages
                          </Badge>
                        ) : (
                          "Messages"
                        )}
                      </Link>
                    </li>
                    {user.isGuest == false && billingStatus !== "false" && (
                      <li>
                        <Link to="/billing">Subscription</Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to="/"
                        onClick={() => {
                          logoutHandle();
                        }}
                        className="link"
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          ) : (
            <>
              <li className={"hidden-md"}>
                <MuiLink
                  component={Link}
                  className="nav-item link sign-in"
                  to="/signin"
                >
                  Sign in
                </MuiLink>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/signup?tutor"
                  onClick={() => setMenuOpen(!menuOpen)}
                  variant="contained"
                  color="primary"
                  className="nav-item join"
                >
                  Sign Up
                  {/*<Box*/}
                  {/*  style={{*/}
                  {/*    position: "absolute",*/}
                  {/*    top: -13,*/}
                  {/*    right: -1,*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <Chip*/}
                  {/*    label="It's Free!"*/}
                  {/*    color="warning"*/}
                  {/*    sx={{*/}
                  {/*      backgroundColor: config["chip-colour"],*/}
                  {/*      p: "2px",*/}
                  {/*      boxShadow: 1,*/}
                  {/*      borderRadius: "2px",*/}
                  {/*    }}*/}
                  {/*    size="small"*/}
                  {/*    variant="filled"*/}
                  {/*  />*/}
                  {/*</Box>*/}
                </Button>
              </li>
            </>
          )}
        </ul>
        {/* Mobile menu  */}
        <div className={"right " + (menuOpen && "active")}>
          <div
            className="hamburger explore-more-mobile "
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>

      <div className={"menu " + (menuOpen && "active")}>
        <ul>
          {isAuth && (
            <li className="full-name">
              <Link
                className="nav-item link"
                to="/dashboard"
                onClick={() => setMenuOpen(!menuOpen)}
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <div
                  className="avatar"
                  style={{
                    backgroundImage: `url(${
                      avatar ? `${API_URL}${avatar}` : defaultAvatar
                    })`,
                    display: "inline-block",
                    justifyContent: "flex-start",
                    border: unreadMessageCount ? "2px solid #f00" : "none",
                  }}
                ></div>
                {fullName}
              </Link>
            </li>
          )}
          <li>
            <Link
              className="nav-item link"
              to="/"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              Home
            </Link>
          </li>
          {isAuth && (
            <>
              <li>
                <Link
                  className="nav-item link"
                  to="/dashboard"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  className="nav-item link"
                  to="/dashboard?tab=message"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  {unreadMessageCount > 0 ? (
                    <Badge badgeContent={unreadMessageCount} color="error">
                      Messages
                    </Badge>
                  ) : (
                    "Messages"
                  )}
                </Link>
              </li>
              {/*<li>*/}
              {/*  <Link*/}
              {/*    className="nav-item"*/}
              {/*    to="/billing"*/}
              {/*    onClick={() => setMenuOpen(!menuOpen)}*/}
              {/*  >*/}
              {/*    <span>Subscription</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
            </>
          )}

          {isAuth && !isGuest && (
            <li>
              <Link
                onClick={() => setMenuOpen(!menuOpen)}
                to="/profile?step=0"
                className={!_id ? "nav-item link red" : "nav-item link"}
              >
                {!_id ? "Create Tutor profile" : "Tutor profile"}
              </Link>
            </li>
          )}
          <li>
            <Link
              className="nav-item link"
              to="/search"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {config["navbar-link"]}
            </Link>
          </li>
          <li className={"hidden-md"}>
            <Link className="nav-item link" to="/courses">
              Courses/classes
            </Link>
          </li>
          {isAuth && user.isGuest == false && billingStatus !== "false" && (
            <li>
              <Link
                className="nav-item link"
                to="/billing"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                Subscription
              </Link>
            </li>
          )}
          <li>
            <Link
              className="nav-item link"
              to="/contact"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              Contact us
            </Link>
          </li>
        </ul>
        <ul>
          {isAuth ? (
            <li>
              <Link
                className="nav-item link"
                to="/"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  logoutHandle();
                }}
              >
                Log out
              </Link>
            </li>
          ) : (
            <>
              <li className={"hidden-md"}>
                <Link
                  className="nav-item link"
                  to="/signup?tutor"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Sign Up
                  {/*<Box*/}
                  {/*  style={{*/}
                  {/*    display: "flex",*/}
                  {/*    justifyContent: "space-between",*/}
                  {/*    alignItems: "center",*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <Chip*/}
                  {/*    label="It's Free!"*/}
                  {/*    color="warning"*/}
                  {/*    sx={{*/}
                  {/*      backgroundColor: config["chip-colour"],*/}
                  {/*      p: "2px",*/}
                  {/*      boxShadow: 1,*/}
                  {/*      borderRadius: "2px",*/}
                  {/*    }}*/}
                  {/*    size="small"*/}
                  {/*    variant="filled"*/}
                  {/*  />*/}
                  {/*</Box>*/}
                </Link>
              </li>

              <li>
                <Link
                  className="nav-item link"
                  to="/signin"
                  onClick={() => {
                    setMenuOpen(!menuOpen);
                  }}
                >
                  Sign in
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
