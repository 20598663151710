import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { CoursesNavbar } from "./Components/Navbar/Navbar";
import { Filter } from "./Components/filter/filter";
import { CourseCard } from "./Components/CourseCard/CourseCard";
import { SearchBars } from "./Components/SearchBars/SearchBars";
import { useParams } from "react-router-dom";
import "./DisplayCourses.css"; // Import CSS

export const DisplayCourses = () => {
  const [id, setId] = useState("");
  const paramsId = useParams();

  useEffect(() => {
    if (paramsId) {
      setId(paramsId);
    } else {
      setId(null);
    }
  }, [paramsId]);

  return (
    <Box className="courses-container">
      {/* Navbar */}
      <CoursesNavbar />

      {/* Search Bar */}
      <Box className="searchnew-container">
        <SearchBars />
      </Box>

      {/* Main Content */}
      <Box className="content-container">
        {/* Filter */}
        <Box className="filter-container">
          <Filter />
        </Box>

        {/* Courses */}
        <Box className="courses-list">
          <CourseCard idd={id} />
        </Box>
      </Box>
    </Box>
  );
};
