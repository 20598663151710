import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { CoursesNavbar } from "./Components/Navbar/Navbar";
import { Filter } from "./Components/filter/filter";
import { CourseCard } from "./Components/CourseCard/CourseCard";
import { SearchBars } from "./Components/SearchBars/SearchBars";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./MyAdverts.css"; // Import CSS
import config from "config";
const MyAdverts = () => {
  const [id, setId] = useState("");
  const paramsId = useParams();
  const location = useLocation();
  console.log("hello");

  useEffect(() => {
    if (paramsId) {
      setId(paramsId);
    } else {
      setId(null);
    }
  }, [paramsId]);

  return (
    <Box className="courses-container">
      {/* Navbar */}
      <CoursesNavbar />

      {/* Search Bar */}

      {/* Main Content */}
      <Box className="content-container">
        {/* Filter */}
        <Box className="filter-container notop">
          <div className="billing-menu notfixed">
            <Link
              className={`tab${
                location.pathname === "/my-courses"
                  ? " active " + config["class-name"]
                  : ""
              }`}
              to="/my-courses"
            >
              My Courses
            </Link>
            <Link
              className={`tab${
                location.pathname === "/my-courses/billing"
                  ? " active " + config["class-name"]
                  : ""
              }`}
              to="/my-courses/billing"
            >
              My Payments
            </Link>
            <Link
              className={`tab${
                location.pathname === "/my-courses/renew"
                  ? " active " + config["class-name"]
                  : ""
              }`}
              to="/my-courses/renew"
            >
              Renew Course
            </Link>
          </div>
        </Box>

        {/* Courses */}
        <Box className="courses-list">
          <CourseCard idd={id} />
        </Box>
      </Box>
    </Box>
  );
};

export default MyAdverts;
