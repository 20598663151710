import React, { useEffect, useState, useCallback, useRef } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  CircularProgress,
  Grid,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
} from "@mui/material";
import { ToDashboardButton } from "../../Dashboard/Components/ToDashboardButton";
import config from "config";

const examBoards = ["AQA", "OCR", "Edexcel", "WJEC", "CCEA", "Other"];

const Step0 = ({ save, loading }) => {
  let userId;
  try {
    const persistedData = localStorage.getItem("persist:root");
    const userData = JSON.parse(persistedData);
    if (userData.userId && typeof userData.userId === "string") {
      userData.userId = userData.userId.replace(/\\/g, "");
    }
    userId = userData?.userId;
  } catch (error) {
    console.error("Error parsing localStorage data:", error);
    userId = null;
  }

  userId = userId.replace(/"/g, "");

  const [subjects, setSubjects] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const debounceTimeoutRef = useRef(null);
  const [rows, setRows] = useState([
    { subject: "", level: "", board: "", price: "", saved: false },
  ]);
  const [isFetchingSubjects, setIsFetchingSubjects] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const next = () => {
    save();
  };

  const handleChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };

  const handleSubjectInputChange = async (index, value) => {
    // Prevent firing on initial load or page reload
    if (rows[index].disabled || rows[index].saved) {
      console.log(`Row ${index} is disabled or saved, not updating.`);
      return; // Skip updates for saved or disabled rows
    }

    // Check if the value has changed
    if (value === rows[index].subject) {
      return; // Don't trigger anything if the value hasn't changed
    }

    // Otherwise, continue with your normal handling
    handleChange(index, "subject", value);

    console.log(
      `handleSubjectInputChange - Subject input changed for row ${index}:`,
      value
    );

    if (value.length === 0) {
      // Reset the filtered subjects and level when subject is cleared for this row only
      setFilteredSubjects(subjects);

      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[index].level = ""; // Reset level for this row only
        return updatedRows;
      });

      console.log("Rows after clearing subject:", rows);
    } else if (value.length > 2) {
      // Debounce the input for subject search
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER}/api/subjects?search=${value}`
          );
          const subjectsData = response.data.subjects;
          setFilteredSubjects(subjectsData);

          console.log("Filtered subjects:", subjectsData);
        } catch (error) {
          console.error("Error fetching filtered subjects:", error);
        }
      }, 300); // 300ms delay for debounce
    }
  };

  const fetchSavedRows = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/subjects/user/${userId}`
      );
      const savedRows = response.data || [];

      const updatedRows = savedRows.map((row) => ({
        ...row,
        isRowDisabled: true, // Explicitly set for saved rows
      }));

      // Add an empty row at the end for new input (no values selected)
      const newEmptyRow = {
        subject: "", // Reset subject value to empty
        level: "", // Reset level value to empty
        board: "", // Reset board value to empty
        price: "", // Reset price value to empty
        isRowDisabled: false, // New row is not disabled
      };

      updatedRows.push(newEmptyRow);

      setRows(updatedRows);
      setIsSaved(true);
      fetchSearchSubjects(); // Fetch subject list for dropdown
      setFilteredSubjects(subjects.slice(0, 10)); // Reset filtered subjects

      console.log("Fetched and updated saved rows:", updatedRows);
    } catch (error) {
      console.error("Error loading saved subjects:", error);
    }
  }, [userId]);

  const fetchSearchSubjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/subjects`
      );
      setSubjects(response.data.subjects);
      setFilteredSubjects(response.data.subjects);

      console.log("Fetched all subjects:", response.data.subjects);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchSavedRows();
      await fetchSearchSubjects();
    };
    fetchData();
  }, [userId]);

  // Log rows whenever they change
  useEffect(() => {
    console.log("Rows updated:", rows);
  }, [rows]);

  const handleSave = async (index) => {
    const newRows = [...rows];
    const rowData = newRows[index];

    // Ensure required fields are filled (subject & price required, level & board optional)
    if (
      !rowData.subject ||
      !rowData.subject.trim() ||
      !rowData.price ||
      String(rowData.price).trim() === ""
    ) {
      Swal.fire({
        title: "Missing Fields",
        text: "Subject and price are required!",
        icon: "warning",
      });
      return;
    }

    try {
      console.log("Sending data to server...");
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/subjects/save/${userId}`,
        {
          subjects: [
            {
              subject: rowData.subject,
              level: rowData.level || "", // Allow empty levels
              board: rowData.board || "", // Allow empty board
              price: rowData.price,
            },
          ],
        }
      );

      console.log("Server response:", response.data);
      newRows[index].saved = true;

      // If it's the last row and all fields are filled, add a new empty row
      if (index === rows.length - 1) {
        const allFieldsFilled = rows.every(
          (row) =>
            row.subject &&
            row.price &&
            row.subject.trim() !== "" &&
            String(row.price).trim() !== ""
        );

        if (allFieldsFilled) {
          newRows.push({
            subject: "",
            level: "",
            board: "",
            price: "",
            saved: false,
          });
        }
      }

      setRows(newRows);
      setIsSaved(true);

      // Show success alert
      Swal.fire({
        title: "Success!",
        text: "Subject saved successfully.",
        icon: "success",
      });

      // Refresh saved rows
      fetchSavedRows();
    } catch (error) {
      console.error("Error saving subject:", error);

      // Show error alert
      Swal.fire({
        title: "Error",
        text: "Failed to save subject. Please try again.",
        icon: "error",
      });

      setIsSaved(false);
    }
  };

  const handleDelete = async (index, subjectId, teacherId) => {
    console.log("subjectId", subjectId);

    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER}/api/subjects/delete/${teacherId}/${subjectId}`
      );
      console.log("Subject deleted successfully");

      // Update rows after deletion
      setRows((prevRows) => prevRows.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting subject:", error);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid
          className="back-to-dashboard"
          sx={{
            textAlign: "right",
            justifyContent: { xs: "start", md: "end" },
            minHeight: "100%",
            display: "flex",
            alignItems: "center",
          }}
          item
          xs={1}
          sm={1}
          md={3}
        >
          <ToDashboardButton />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ mb: 2 }}>
            <p
              className={`step-title ${config["primary-text-colour-class"]}`}
              style={{ lineHeight: "30px" }}
            >
              <span>What subjects can you teach?</span>
              <br />
            </p>
          </Box>
          <TableContainer
            className="subjecttab"
            component={Paper}
            elevation={0}
            sx={{ maxWidth: "1000px", margin: "auto", boxShadow: "none" }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: "0.85rem", width: "25%" }}>
                    Subject
                  </TableCell>
                  <TableCell sx={{ fontSize: "0.85rem", width: "25%" }}>
                    Level
                  </TableCell>
                  <TableCell sx={{ fontSize: "0.85rem", width: "20%" }}>
                    Exam Board
                  </TableCell>
                  <TableCell sx={{ fontSize: "0.85rem", width: "15%" }}>
                    Price (£/hr)
                  </TableCell>
                  <TableCell sx={{ fontSize: "0.85rem", width: "15%" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row, index) => {
                  const selectedSubject = subjects.find(
                    (sub) => sub.name === row.subject
                  );
                  const availableLevels = selectedSubject
                    ? selectedSubject.levels
                    : [];

                  const isRowDisabled = row.isRowDisabled; // Disable the row if it's saved
                  console.log(" isRowDisabled", isRowDisabled);
                  console.log("availableLevels selected", availableLevels);
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Autocomplete
                          value={row.subject}
                          onInputChange={(_, value) =>
                            handleSubjectInputChange(index, value)
                          }
                          onChange={(_, value) =>
                            handleChange(index, "subject", value || "")
                          }
                          disabled={isRowDisabled} // Disable input for saved rows
                          options={filteredSubjects.map(
                            (subject) => subject.name
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="outlined"
                              label="Subject"
                              fullWidth
                            />
                          )}
                          popperprops={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 8], // This controls the distance between the input and the dropdown
                                },
                              },
                              {
                                name: "flip",
                                enabled: true, // Allow flip behavior if the dropdown doesn't fit below
                              },
                            ],
                          }}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          placeholder="Level"
                          value={
                            row.subject && availableLevels.length === 0
                              ? "No Needed"
                              : row.level || ""
                          } // Set "No Needed" only if subject is selected and no levels exist
                          onChange={(e) =>
                            handleChange(index, "level", e.target.value)
                          }
                          disabled={isRowDisabled}
                          select
                          size="small"
                          variant="outlined"
                          label="Level"
                          fullWidth
                        >
                          {row.subject && availableLevels.length === 0 ? (
                            <MenuItem value="Not needed">Not Needed</MenuItem>
                          ) : (
                            availableLevels.map((level) => (
                              <MenuItem key={level} value={level}>
                                {level}
                              </MenuItem>
                            ))
                          )}
                        </TextField>
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={row.board}
                          onChange={(e) =>
                            handleChange(index, "board", e.target.value)
                          }
                          disabled={isRowDisabled}
                          select
                          size="small"
                          variant="outlined"
                          label="Exam Board"
                          fullWidth
                        >
                          {examBoards.map((board) => (
                            <MenuItem key={board} value={board}>
                              {board}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={row.price}
                          onChange={(e) =>
                            handleChange(index, "price", e.target.value)
                          }
                          disabled={isRowDisabled}
                          size="small"
                          variant="outlined"
                          label="Price (£)"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        {isRowDisabled ? (
                          <>
                            <Box sx={{ display: "flex", gap: 1 }}>
                              {/* <Button
                              variant="contained"
                              onClick={() =>
                              handleEdit(index, String(row._id), userId)
                              }
                              color="primary"
                              size="small"
                              >
                              Edit
                              </Button> */}

                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleDelete(index, String(row._id), userId)
                                }
                                color="error"
                                size="small"
                                sx={{ width: "100%", margin: 0 }}
                              >
                                Delete
                              </Button>
                            </Box>
                          </>
                        ) : (
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Button
                              variant="contained"
                              onClick={() => handleSave(index)}
                              color="primary"
                              size="small"
                              sx={{ width: "100%", margin: 0 }}
                            >
                              Save
                            </Button>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <span className="button-boxs">
              <div>
                <Button
                  type="button"
                  size="large"
                  onClick={next}
                  variant="contained"
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress
                      size="20px"
                      style={{ marginRight: "10px", color: "white" }}
                    />
                  )}
                  Next
                </Button>
              </div>
            </span>
          </TableContainer>

          {loading && (
            <Box sx={{ textAlign: "center", marginTop: "20px" }}>
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step0;
