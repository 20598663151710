import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Box from "@mui/material/Box";
import { Modal, FormControl, FormControlLabel } from "@mui/material";
import { FormGroup } from "@mui/material";
import { Checkbox } from "@mui/material";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { MuiTelInput } from "mui-tel-input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { TextField } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { useNavigate } from "react-router-dom";
import CssTextField from "../../Components/CssTextField";
import { ReactComponent as Curve } from "../../assets/images/undercurve.tsx";
import "./AddCourse.css";
import { CircularProgress } from "@mui/material";
import { createAxiosInstance } from "../../api";
import CourseBilling from "../CourseBilling/CourseBilling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { CreateCourseContext } from "./AddCourseContext/AddContextContext";
import Swal from "sweetalert2";
import InputAdornment from "@mui/material/InputAdornment";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import dayjs, { Dayjs } from "dayjs";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey); // Replace with your actual Stripe publishable key

const AddCourse = () => {
  console.log(ClassicEditor.version);

  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState();
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [level, setLevel] = useState("Level");
  const [types, setType] = useState("online");
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [editorData, setEditorData] = useState("");
  const [isOngoing, setIsOngoing] = useState(false);
  const [previousState, setPreviousState] = useState(null);
  const { setsendReq, sendReq, coursePrice, setCoursePrice } =
    useContext(CreateCourseContext);
  const [selectedImages, setSelectedImages] = React.useState([]);

  const { id } = useParams();
  const handleClose = () => {
    setIsPopUpOpen(false);
    setLoading(false);
  };

  const handleClick = () => {
    setSnackBarOpen(true);
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };
  React.useEffect(() => {
    setCourse((prevCourse) => ({
      ...prevCourse,
      level: level,
    }));
  }, [level]);

  const [course, setCourse] = React.useState({
    name: "",
    level: "Level",
    date: "",
    time: "",
    duration: "Duration of Advertising",
    addOne: "",
    addTwo: "",
    postCode: "",
    town: "",
    city: "",
    telephone: "",
    description: "",
    image: "",
    advPrice: "",
    coursePrice: "",
    file: "",
    email: "",
    socialLink: "",
    by: "",
    types: "",
    contactSources: "",
    userId: "",
    isOngoing: "",
  });

  const levels = [
    {
      value: "primary-7-11",
      key: "Primary (7/11+)",
    },
    {
      value: "primary-5-11",
      key: "Primary (5-11 yrs)",
    },
    {
      value: "secondary-11-14",
      key: "Seconday (11-14 yrs)",
    },
    {
      value: "gcse-15-16",
      key: "GCSE (15-16 yrs)",
    },
    {
      value: "a-level-5-18",
      key: "A-level (15-18 yrs)",
    },
    {
      value: "degree-adults",
      key: "Degree/Adults",
    },
    {
      value: "other",
      key: "Other",
    },
  ];

  const price = [
    {
      key: "1",
      value: "£4.99",
    },
    {
      key: "2",
      value: "£7.99",
    },
    {
      key: "6",
      value: "£12.99",
    },
    {
      key: "12",
      value: "£19.99",
    },
  ];

  const getSingleCourseData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/courses/${id}`
      );
      if (response && response.data.length > 0) {
        const courseData = response.data[0];
        const { date, time, ...filteredCourseData } = courseData;
        console.log(courseData);
        filteredCourseData.date = [dayjs(date[0]), dayjs(date[1])];
        setCourse({ ...filteredCourseData });
        setLevel(filteredCourseData.level);
      }
    } catch (error) {
      console.error("Error in fetching Course");
    }
  }, [id]);
  useEffect(() => {
    if (id) getSingleCourseData();
  }, [getSingleCourseData, id]);

  useEffect(() => {
    console.log(course?.image);
  }, [course]);

  const removeMUILicenseIssue = useCallback(() => {
    setTimeout(() => {
      const targetText = "MUI X Missing license key";
      const divs = document.querySelectorAll("div");
      divs.forEach((div) => {
        if (div.textContent.trim() === targetText) {
          div.classList.add("hide");
        }
      });
    }, 100);
  }, []);

  useEffect(() => {
    if (isDateOpen) {
      removeMUILicenseIssue();
    }
  }, [isDateOpen, removeMUILicenseIssue]);

  useEffect(() => {
    setCoursePrice(course.advPrice);
  }, [course.advPrice, setCoursePrice]);

  const fileInputRef = useRef(null);
  const inputRef = useRef(null);

  const removeSelectedFile = () => {
    setCourse({ ...course, file: null });
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clears the file input
    }
  };

  const removeSelectedImages = () => {
    setSelectedImages([]);
    setCourse((prevCourse) => ({
      ...prevCourse,
      image: "", // Ensure the image name is fully cleared
    }));

    // Reset the file input
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the first file

    if (!file) return;

    console.log("Selected File:", file);

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      if (img.width < 200 || img.height < 200) {
        handleClick(); // Show error message
      } else {
        setCourse((prevCourse) => ({
          ...prevCourse,
          image: file, // Store full file object, not just name
        }));

        const reader = new FileReader();
        reader.onload = (e) => {
          setSelectedImages([{ file, url: e.target.result }]); // Update preview
        };
        reader.readAsDataURL(file);
      }
    };

    img.onerror = () => {
      alert("Invalid image file.");
    };
  };

  const createCourse = useCallback(
    async (course) => {
      if (!id) {
        if (!sendReq) {
          return;
        }
      }
      const persistedData = localStorage.getItem("persist:root");

      let userId;
      try {
        const userData = JSON.parse(persistedData);
        if (userData.userId && typeof userData.userId === "string") {
          userData.userId = userData.userId.replace(/\\/g, "");
        }
        userId = userData?.userId;
      } catch (error) {
        console.error("Error parsing localStorage data:", error);
        userId = null;
      }

      const formData = new FormData();
      if (typeof course.image !== "string") {
        formData.append("image", course.image);
      } else {
        delete course.image;
      }
      if (typeof course.file !== "string") {
        formData.append("file", course.file);
      } else {
        delete course.file;
      }
      formData.append(
        "courseData",
        JSON.stringify({
          ...course,
          token: token,
          userId: userId,
        })
      );

      if (id) {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_SERVER}/api/courses/edit/${id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(response);
          Swal.fire({
            imageUrl: "/assets/success-icon.png",
            imageHeight: "100px",
            title: "Course updated successfully!",
            html: "Your course details have been updated.",
            width: 600,
            confirmButtonColor: "#0099FF",
            customClass: {
              icon: "no-before-icon",
            },
          });
          navigate("/courses");
        } catch (err) {
          Swal.fire({
            imageUrl: "/assets/error-icon.png",
            imageHeight: "100px",
            title: "Oops...",
            text:
              err?.response?.data?.message ||
              "Your course could not be updated, please try again!",
            confirmButtonColor: "#0099FF",
          });
        }
      } else {
        try {
          const response = await createAxiosInstance().post(
            `/api/courses`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          Swal.fire({
            imageUrl: "/assets/success-icon.png",
            imageHeight: "100px",
            title: "Thank you for your payment.",
            html: "Your advert will appear once this has been approved by admin team (within 24 hours). You will also receive an email.",
            width: 600,
            confirmButtonColor: "#0099FF",
            customClass: {
              icon: "no-before-icon",
            },
          });
          navigate("/my-courses");
        } catch (err) {
          Swal.fire({
            imageUrl: "/assets/error-icon.png",
            imageHeight: "100px",
            title: "Oops...",
            text:
              err?.response?.data?.message ||
              "Your course could not be created, please try again!",
            confirmButtonColor: "#0099FF",
          });
        }
      }

      setsendReq(false);
      setLoading(false);
    },
    [id, navigate, sendReq, token]
  );

  useEffect(() => {
    if (!id) {
      console.log("Course ID:", id);
      console.log("SendReq-2", sendReq);
      if (sendReq == true || sendReq == "true") {
        createCourse(course);
        console.log("Create Course called");
      }
    }
  }, [sendReq, course, setsendReq, id, createCourse]);

  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    setCourse((prevCourse) => {
      const updatedTypes = prevCourse.types.includes(value)
        ? prevCourse.types.filter((type) => type !== value)
        : [...prevCourse.types, value];

      const shouldShowAddress = updatedTypes.includes("in-person");
      setShowAddress(shouldShowAddress);

      return { ...prevCourse, types: updatedTypes };
    });
  };

  const handleContactCheckboxChange = (event) => {
    const { value } = event.target;

    setCourse((prevCourse) => {
      const updatedContactSources = prevCourse.contactSources.includes(value)
        ? prevCourse.contactSources.filter((source) => source !== value)
        : [...prevCourse.contactSources, value];
      return { ...prevCourse, contactSources: updatedContactSources };
    });
  };

  const handleOnGoingChange = (event) => {
    setIsOngoing(event.target.checked);
    const isChecked = event.target.checked;

    setCourse((prevCourse) => {
      const updatedCourse = {
        ...prevCourse,
        isOngoing: isChecked ? "On-going" : "Not On-going",
      };

      if (Array.isArray(prevCourse.date)) {
        const filteredDates = prevCourse.date.filter((_, index) => index !== 1);
        updatedCourse.date = filteredDates;
      }

      console.log(updatedCourse.date);
      console.log(updatedCourse.name);

      return updatedCourse;
    });
  };
  const handleDateChange = (newValue) => {
    setCourse((prevCourse) => {
      const updatedCourse = {
        ...prevCourse,
        date: newValue,
      };

      if (
        prevCourse.isOngoing === "On-going" &&
        Array.isArray(updatedCourse.date)
      ) {
        let filteredDates = updatedCourse.date.filter(
          (_, index) => index !== 1
        );
        if (filteredDates.length === 1 && filteredDates[0] === null) {
          filteredDates = [];
        }
        updatedCourse.date = filteredDates;
      }

      return updatedCourse;
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let newError = {};

    if (course.name.trim().length === 0)
      newError.name = "Please input Course Name.";
    if (
      (!course?.date?.length || course.date.length === 0) &&
      course.isOngoing.length === 0
    )
      newError.date = "Please input Course Date or Select OnGoing";
    if (course.duration.trim().length === 0)
      newError.duration = "Please input Course Duration.";
    // if (course.types.trim().length === 0) newError.type = 'Please select Course Type.';
    //if (!course.file) newError.file = "Please upload a PDF file.";
    if (!course.image) newError.image = "Please upload an image.";
    if (course.advPrice.trim().length === 0)
      newError.advPrice = "Please Select a Package.";
    if (course.types === "In-Person" && course.addOne.length === 0)
      newError.address = "Please Enter Address";
    if (
      course.contactSources.includes("email") &&
      course.email.trim().length === 0
    )
      newError.email = "Please Enter Email";
    if (
      course.contactSources.includes("phone") &&
      course.telephone.trim().length === 0
    )
      newError.telephone = "Please Enter Telephone";
    if (course.email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(course.email)) {
        newError.email = "Please enter a valid email address.";
      }
    }
    if (course.contactSources.length === 0)
      newError.contactSources = "Please select a contact source.";
    setError(newError);

    if (Object.keys(newError).length > 0) return;

    setLoading(true);
    if (id) {
      createCourse(course);
    }
    if (!id) {
      setIsPopUpOpen(true);
    }
  };

  const handlePhoneNumber = (newValue) => {
    setCourse({ ...course, telephone: newValue });
  };
  return (
    <div
      className="contact-container"
      style={{
        padding: "20px",
        maxWidth: "100%",
        width: { sm: "90%" },
        padding: "10px",
        boxSizing: "border-box",
      }}
    >
      <div
        className="contact bd-grey"
        style={{ maxWidth: "800px", margin: "0 auto" }}
      >
        <div
          className="page-heading"
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "30px",
            fontWeight: "bolder",
          }}
        >
          Advertise Your course/class
          <Curve style={{ maxWidth: "100%", marginTop: "20px !important" }} />
        </div>
        <Box
          component="form"
          className="boxx"
          onSubmit={handleSubmit}
          sx={{
            // "& .MuiTextField-root": { m: 1, maxWidth: "100%" },
            "& .captcha": { m: 1, mt: 2 },
            "& .submitbtn": { m: 1, mt: 4 },
            "& .heading": { m: 1, textAlign: "center" },
          }}
          noValidate
          autoComplete="off"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="form" style={{ width: "100%", maxWidth: "600px" }}>
            <div style={{ marginBottom: "20px" }}>
              <CssTextField
                required
                id="name-of-course"
                label="Name of course/class"
                value={course?.name}
                onChange={(e) => setCourse({ ...course, name: e.target.value })}
                style={{
                  width: "100%",
                  height: "50px",
                  marginBottom: "5px",
                }}
              />
              {error.name && (
                <>
                  <br />
                  <span style={{ color: "red" }}>{error.name}</span>
                </>
              )}
              {/* {error.name && <span style={{ color: 'red' }}>{error.name}</span>} */}
              <Select
                id="demo-simple-select"
                value={level} // This stores the value
                onChange={(e) => setLevel(e.target.value)} // Store only value
                displayEmpty
                placeholder="level"
                renderValue={(selectedValue) => {
                  if (!selectedValue) return <p>Level</p>;

                  // Find the key that corresponds to the stored value
                  const selectedItem = levels.find(
                    ({ value }) => value === selectedValue
                  );
                  return selectedItem ? selectedItem.key : selectedValue; // Show key
                }}
                style={{ maxWidth: "280px", width: "100%", marginTop: "20px" }}
              >
                {levels.map(({ key, value }) => (
                  <MenuItem key={key} value={value}>
                    {" "}
                    {/* Store value, but show key */}
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                style={{ marginTop: "20px" }}
              >
                <DateRangePicker
                  required
                  localeText={{
                    start: "Start Date",
                    end: isOngoing ? "On Going" : "End Date",
                  }}
                  id="date-of-course"
                  label="Date Of Course"
                  value={course.date ?? []}
                  onChange={handleDateChange}
                  onOpen={() => {
                    if (!isOngoing) {
                      setIsDateOpen(true);
                    } else {
                      setIsDateOpen(false);
                    }
                    removeMUILicenseIssue();
                  }}
                  onClose={() => setIsDateOpen(false)}
                  style={{ width: "100%" }}
                  slotProps={{
                    textField: ({ position }) => ({
                      disabled: isOngoing && position === "end",
                    }),
                  }}
                  disabled={
                    isOngoing && course.date.length > 0 && course.date[0]
                  }
                />
              </LocalizationProvider>
              {error.date && <span style={{ color: "red" }}>{error.date}</span>}
              <Box className="on-going" style={{ marginTop: "20px" }}>
                <FormControl component="fieldset">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isOngoing}
                          onChange={handleOnGoingChange}
                          name="on-going"
                        />
                      }
                      label="On-going"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <div style={{ width: "100%", marginTop: "20px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    id="time-of-course"
                    label="Time of Course"
                    onChange={(time) => setCourse({ ...course, time })}
                    ampm={false} // 24-hour format (optional)
                    openTo="hours" // Opens directly to hours selection
                    views={["hours", "minutes"]} // Limits views to only hours and minutes
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div
              style={{
                alignItems: "flex-start",
              }}
            >
              <FormControl component="fieldset" style={{ marginTop: "20px" }}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={course?.types?.includes("in-person")}
                        onChange={handleCheckboxChange}
                        value="in-person"
                      />
                    }
                    label="In-Person"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={course?.types?.includes("online")}
                        onChange={handleCheckboxChange}
                        value="online"
                      />
                    }
                    label="Online"
                  />
                </FormGroup>
              </FormControl>

              {showAddress && (
                <div>
                  <div style={{ marginTop: "20px;" }}>
                    <CssTextField
                      required
                      fullWidth
                      label="Address Line 1"
                      variant="outlined"
                      margin="normal"
                      onChange={(e) =>
                        setCourse({ ...course, addOne: e.target.value })
                      }
                    />
                    {error.address && (
                      <span style={{ color: "red" }}>{error.address}</span>
                    )}
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <CssTextField
                      fullWidth
                      label="Address Line 2 (optional)"
                      variant="outlined"
                      margin="normal"
                      onChange={(e) =>
                        setCourse({ ...course, addTwo: e.target.value })
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <CssTextField
                      fullWidth
                      label="Town (optional)"
                      variant="outlined"
                      margin="normal"
                      onChange={(e) =>
                        setCourse({ ...course, town: e.target.value })
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <CssTextField
                      fullWidth
                      label="City (optional)"
                      variant="outlined"
                      margin="normal"
                      onChange={(e) =>
                        setCourse({ ...course, city: e.target.value })
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <CssTextField
                      required
                      fullWidth
                      label="Postcode"
                      variant="outlined"
                      margin="normal"
                      onChange={(e) =>
                        setCourse({ ...course, postCode: e.target.value })
                      }
                    />
                  </div>
                </div>
              )}

              {/* <Select
                id="course-type"
                value={course.types}
                onChange={(e) => setCourse({ ...course, types: e.target.value })}
                style={{ width: '100%' }}
              >
                {type.map(({ key, value }) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select> */}
            </div>
            {/* <CssTextField
              required
              id="course-location"
              label="Course Location"
              value={course.location}
              onChange={(e) => setCourse({ ...course, location: e.target.value })}
              style={{ width: '100%', marginBottom: '20px', padding: '8px', marginLeft: '5px', boxSizing: 'border-box', height: '50px' }}
            /> */}
            <div style={{ marginTop: "20px" }}>
              <CssTextField
                id="price-of-course"
                label="Course Price"
                value={course?.coursePrice}
                onChange={(e) =>
                  setCourse({ ...course, coursePrice: e.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  height: "50px",
                }}
              />
            </div>
            <Box sx={{ marginTop: "20px" }}>
              <label className="des">Description</label>
              <Box sx={{ paddingTop: "10px" }}></Box>
              <CKEditor
                editor={ClassicEditor}
                data={course?.description || ""}
                config={{
                  height: "20vh",
                  styles: {
                    height: "500px",
                  },
                  toolbar: ["bold", "bulletedList"],
                  placeholder:
                    "Write a clear description about the course or class you are offering",
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setCourse((prevCourse) => ({
                    ...prevCourse,
                    description: data,
                  }));
                  // setEditorData(data);
                  // console.log({ event, editor, data });
                }}
                style={{
                  border: "none", // You can adjust this according to your layout
                }}
              />
            </Box>
            {/* <TextareaAutosize
              id="description"
              placeholder="Course Description"
              value={course.description}
              onChange={(e) => setCourse({ ...course, description: e.target.value })}
              minRows={3}
              style={{ width: '100%', padding: '8px', marginLeft: '10px', height: '20vh', marginBottom: '20px', boxSizing: 'border-box' }}
            /> */}
            <div style={{ marginTop: "20px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                Upload File
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "15px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  Upload information about your course/class for users to
                  download. <br /> <br />
                  Accept: PDF, Word Doc.
                </span>
              </label>

              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  id="file"
                  onChange={(e) =>
                    setCourse({ ...course, file: e.target.files[0] })
                  }
                  style={{ flexGrow: 1, marginLeft: "0", marginRight: "0" }}
                />

                {course.file && (
                  <HighlightOffRoundedIcon
                    className="remove-file-icon"
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                    onClick={removeSelectedFile}
                  />
                )}
              </div>

              {error.file && <span style={{ color: "red" }}>{error.file}</span>}
            </div>

            <div style={{ marginTop: "20px" }}>
              <div
                className="d-flex uploadedimages"
                style={{ display: "flex", alignItems: "center" }}
              >
                {selectedImages.length > 0 ? (
                  selectedImages.map((img, index) => (
                    <div
                      key={index}
                      className="image-container mt-2 ml-4"
                      style={{ display: "flex" }}
                    >
                      <img
                        className="uploaded_image"
                        src={img.url}
                        style={{
                          width: "218px",
                          height: "154px",
                        }}
                        alt={`Selected preview ${index}`}
                      />
                      <HighlightOffRoundedIcon
                        style={{
                          width: "30px",
                          height: "30px",
                          position: "relative",
                          left: "10px",
                        }}
                        onClick={removeSelectedImages}
                      />
                    </div>
                  ))
                ) : course?.image ? (
                  <div
                    className="image-container mt-2 ml-4"
                    style={{ display: "flex" }}
                  >
                    <img
                      className="uploaded_image"
                      src={`${process.env.REACT_APP_SERVER}/${course?.image}`}
                      alt="Course preview"
                      style={{
                        width: "218px",
                        height: "154px",
                      }}
                    />
                  </div>
                ) : (
                  <div className="placeholder">
                    <img
                      src="/assets/course-place.png"
                      alt="Placeholder"
                      style={{
                        width: "218px",
                        height: "154px",
                      }}
                    />
                  </div>
                )}
                {!course?.image && (
                  <p style={{ marginLeft: "5%" }}>
                    Only JPEG or PNG files are allowed.
                  </p>
                )}
              </div>
              <label
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                Upload Cover Image
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "15px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  Your cover image will be displayed on the website. It gives
                  parents/students information what your course or class is
                  about.
                </span>
              </label>{" "}
              <input
                type="file"
                id="image"
                ref={inputRef} // Assign ref here
                onChange={handleImageChange}
                style={{ width: "100%", marginLeft: "0px" }}
              />
              {error.image && (
                <span style={{ color: "red" }}>{error.image}</span>
              )}
              <Snackbar
                open={snackBarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                message="Image must at least 200 x 200 pixels."
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                  backgroundColor: "red",
                }}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <CssTextField
                id="social-link"
                label="Add your Website (optional)"
                value={course.socialLink}
                onChange={(e) =>
                  setCourse({ ...course, socialLink: e.target.value })
                }
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  height: "50px",
                }}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <CssTextField
                id="by"
                label="Course by (optional)"
                value={course.by}
                onChange={(e) => setCourse({ ...course, by: e.target.value })}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  height: "50px",
                }}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: "15px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Select the form of communication through which potential clients
                can get in touch awith you. This can be via telephone, email, or
                our message system. You must select at least one method.
              </span>
              <br />
              <br />
              <span
                style={{
                  fontWeight: "normal",
                  fontSize: "15px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Tick which way you would like other to get in touch with you
              </span>
              <FormControl component="fieldset">
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={course?.contactSources?.includes("email")}
                        onChange={handleContactCheckboxChange}
                        value="email"
                      />
                    }
                    label="Email"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={course?.contactSources?.includes("phone")}
                        onChange={handleContactCheckboxChange}
                        value="phone"
                      />
                    }
                    label="Phone"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={course?.contactSources?.includes(
                          "our-message-system"
                        )}
                        onChange={handleContactCheckboxChange}
                        value="our-message-system"
                      />
                    }
                    label="Our message system"
                  />
                </FormGroup>
              </FormControl>
              {error.contactSources && (
                <>
                  <br />
                  <span style={{ color: "red" }}>{error.contactSources}</span>
                </>
              )}
            </div>
            {course.contactSources.includes("email") && (
              <div style={{ marginTop: "20px" }}>
                <CssTextField
                  id="social-link"
                  label="Please add your email for contact"
                  value={course.email}
                  onChange={(e) =>
                    setCourse({ ...course, email: e.target.value })
                  }
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    marginBottom: "5px",
                    height: "50px",
                  }}
                />
                {error.email && (
                  <span style={{ color: "red" }}>{error.email}</span>
                )}
              </div>
            )}
            {course.contactSources.includes("phone") && (
              <div style={{ marginTop: "20px" }}>
                <MuiTelInput
                  id="telephone"
                  label="Telephone"
                  value={course.telephone}
                  onChange={handlePhoneNumber}
                  defaultCountry="GB"
                  style={{
                    width: "100%",
                    marginBottom: "5px",
                  }}
                />
                {error.telephone && (
                  <span style={{ color: "red" }}>{error.telephone}</span>
                )}
              </div>
            )}
            <div style={{ marginTop: "20px" }}>
              <Select
                required
                id="demo-simple-select"
                value={course.duration}
                onChange={(e) => {
                  const selectedOption = price.find(
                    (option) => option.key === e.target.value
                  );
                  setCourse({
                    ...course,
                    advPrice: selectedOption.value,
                    duration: selectedOption.key,
                  });
                }}
                displayEmpty
                renderValue={(value) => {
                  return (
                    price.find((el) => el.key === value)?.value ?? (
                      <span>Duration of advertising</span>
                    )
                  );
                }}
                style={{ width: "100%" }}
                error={error.duration}
                helperText={error.duration}
              >
                {price.map(({ key, value }) => (
                  <MenuItem key={key} value={key}>
                    {value} (
                    {key === "1"
                      ? `${key} Month`
                      : key === "12"
                      ? "Year"
                      : `${key} Months`}
                    )
                  </MenuItem>
                ))}
              </Select>
              {error.advPrice && (
                <span style={{ color: "red" }}>{error.advPrice}</span>
              )}
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="submitbtn"
              style={{
                marginTop: "20px",
                backgroundColor: "#712d93",
                color: "#fff",
                padding: "10px 20px",
                fontSize: "16px",
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Box>
        <Modal
          open={isPopUpOpen}
          onClose={handleClose}
          sx={{ zIndex: 1, top: "112px" }}
        >
          <Box
            sx={{
              position: "relative",
              width: { sm: "100%", md: "100%", lg: "100%", xs: "100%" },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
              height: { sm: "100%", md: "100%", lg: "100%", xs: "100%" },
              overflowY: { sm: "auto", md: "auto", lg: "auto", xs: "auto" },
            }}
          >
            <Typography
              onClick={handleClose}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Typography>

            <CourseBilling
              advancePrice={course.advPrice}
              duration={course.duration}
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default AddCourse;
