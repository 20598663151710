import config from "config";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_URL, createAxiosInstance } from "../../../api";

const ProfileStepInformationStatuses = () => {
  const navigate = useNavigate();
  const [billingStatus, setBillingStatus] = useState("false"); // Local state for billing status
  const [subjectsCom, setSubjectsCom] = useState(false); // Use state to track subject completion

  // Get profile data from Redux
  const {
    avatar,
    about,
    price,
    qualifications,
    title,
    online,
    faceToFace,
    gender,
    isAgency,
    links,
    publish,
    email,
    phone,
    exam,
    level,
    _id,
    status,
  } = useSelector((state) => state.profile.user);

  const userId = useSelector((state) => state.profile.user.user);
  console.log("payment status is:", billingStatus);

  const checkUserSubjects = useCallback(async () => {
    if (!userId) {
      console.log("User ID is undefined or invalid. Skipping subject check.");
      return; // Exit the function early if the userId is not valid
    }

    try {
      console.log("Fetching saved subjects for user:", userId);
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_SERVER}/api/subjects/user/${userId}`
      );
      console.log("Saved subjects response:", response.data);

      const subCompleted = response.data || [];
      if (subCompleted.length > 0) {
        // There is at least one entry in the array
        setSubjectsCom(true); // Set subjects completion to true in state
        console.log("At least one entry exists");
      } else {
        setSubjectsCom(false); // Set subjects completion to false in state
        console.log("No entries found");
      }

      console.log("Subjects completed", subCompleted);
    } catch (error) {
      console.error(
        "Error checking subjects status:",
        error.response?.data?.message || error.message
      );
    }
  }, [userId]);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (!userId) {
        console.log("User ID is undefined or invalid. Skipping payment check.");
        return; // Exit the function early if the userId is not valid
      }

      try {
        console.log("Checking payment status for user:", userId);

        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.post(
          `${API_URL}/api/billings/hasPaid/${userId}`
        );

        console.log("Payment status response:", response.data);

        if (response.data && response.data.paid) {
          console.log("User has paid:", response.data.paid);

          setBillingStatus(response.data.paid);
        } else {
          console.log("User has not paid.");

          setBillingStatus("false");
        }
      } catch (error) {
        console.error(
          "Error checking payment status:",
          error.response?.data?.message || error.message
        );
        setBillingStatus("false");
      }
    };

    checkPaymentStatus();
    checkUserSubjects();
  }, [userId]); // Re-run the effect when `userId` changes

  return (
    <>
      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=0")}
        >
          <strong>{config["profile-step-0-title"]}</strong>
        </span>
        {subjectsCom ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>Incomplete</span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=1")}
        >
          <strong>What exam board can you teach?</strong>
          <span style={{ color: "#929292", fontSize: "16px" }}>
            {" "}
            (Optional)
          </span>
        </span>
        {Object.keys(exam).some((k) => exam[k]) ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span
            className={"profile-cat-not-done"}
            style={{ color: "lightgray" }}
          >
            <hr
              style={{
                width: "94px",
                borderColor: "lightgray",
                border: "none",
                margin: "14px 0px",
                height: "1.2px",
                backgroundColor: "lightgray",
              }}
            />
          </span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=2")}
        >
          <strong>Select your tutoring options</strong>
        </span>
        {online || faceToFace ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>Incomplete</span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=3")}
        >
          <strong>Make Payment for Subscription</strong>
        </span>
        {billingStatus !== "false" ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>Incomplete</span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=4")}
        >
          <strong>Create your tutor profile</strong>
        </span>
        {isAgency !== -1 &&
        title &&
        gender !== -1 &&
        publish !== -1 &&
        about &&
        about !== "<p><br></p>" &&
        avatar ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>Incomplete</span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=5")}
        >
          <strong>Tell us about your qualifications</strong>
        </span>
        {Object.keys(qualifications).some((k) => qualifications[k] !== -1) ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>Incomplete</span>
        )}
      </div>

      <div className={"profile-detail-section"}>
        <span
          className={`profile-cat ${config["primary-text-colour-class"]}`}
          onClick={() => navigate("/profile?step=6")}
        >
          <strong>How do clients get in touch with you?</strong>
          <span style={{ color: "#929292", fontSize: "16px" }}>(Optional)</span>
        </span>
        {email || phone ? (
          <span className={"profile-cat-done"}>Completed</span>
        ) : (
          <span className={"profile-cat-not-done"}>
            <hr
              style={{
                width: "94px",
                borderColor: "lightgray",
                border: "none",
                margin: "14px 0px",
                height: "1.2px",
                backgroundColor: "lightgray",
              }}
            />
          </span>
        )}
      </div>
    </>
  );
};

export default ProfileStepInformationStatuses;
