import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import stripeLogo from "../../../assets/stripelogo.png";

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { API_URL, createAxiosInstance } from "../../../api";
import "./Step0.css";

const Step2 = (props) => {
  const { goBack, save, loading } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [paid, setPaid] = useState(false); // Initialize paid state
  const [paidStatus, setPaidStatus] = useState("false"); // Initialize paid status
  const [lastName, setLastName] = useState(""); // State for last name
  const [firstName, setFirstName] = useState(""); // State for first name
  const [subscriptionType, setSubscriptionType] = useState("monthly");
  const [paymentLoading, setPaymentLoading] = useState(false);

  const cardElementStyles = {
    base: {
      backgroundColor: "light-grey",
      fontSize: "18px",
      color: "#32325d",
      "::placeholder": {
        color: "#aab7c4",
      },
      border: "1px solid #ccc",
      borderRadius: "4px",
      padding: "10px",
      marginBottom: "16px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
    focus: {
      borderColor: "#712d93",
    },
  };

  const userId = useSelector((state) => state.profile.user.user);

  const handlePayment = async () => {
    setPaymentLoading(true);

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text: "Card element is not available.",
      });
      setPaymentLoading(false);
      return;
    }

    try {
      const { error, token } = await stripe.createToken(cardNumberElement);

      if (error) {
        Swal.fire({
          icon: "error",
          title: "Payment Failed",
          text: error.message,
        });
        setPaymentLoading(false);
        return;
      }

      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(
        `${API_URL}/api/billings/stripe`,
        {
          token: token.id,
          subscriptionType,
          name: `${firstName} ${lastName}`,
          userId,
        }
      );

      if (response.data && response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Payment Successful",
          text: "Your subscription has been activated!",
        }).then(() => {
          setPaid(true);
          setPaidStatus("Paid");
          handleNext(); // Ensure this is called after the user closes the popup
        });
      } else {
        throw new Error(response.data.message || "Payment failed");
      }
    } catch (error) {
      console.error("Payment processing error:", error);
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text:
          error.response?.data?.message ||
          "There was an issue processing your payment.",
      });
    } finally {
      setPaymentLoading(false);
    }
  };

  console.log("payment status is:", paidStatus);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (!userId) {
        console.log("User ID is undefined or invalid. Skipping payment check.");
        return; // Exit the function early if the userId is not valid
      }

      try {
        console.log("Checking payment status for user:", userId);

        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.post(
          `${API_URL}/api/billings/hasPaid/${userId}`
        );

        console.log("Payment status response:", response.data);

        if (response.data && response.data.paid) {
          console.log("User has:", response.data.paid);
          setPaid(true);
          setPaidStatus(response.data.paid);
        } else {
          console.log("User has not paid.");
          setPaid(false);
          setPaidStatus("false");
        }
      } catch (error) {
        console.error(
          "Error checking payment status:",
          error.response?.data?.message || error.message
        );
        setPaidStatus("false");
      }
    };

    checkPaymentStatus();
  }, [userId]); // Re-run the effect when `userId` changes

  const handleNext = () => {
    save();
  };

  const back = () => {
    goBack();
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className="boxy main"
      sx={{ p: 3 }}
    >
      <Typography variant="h4" align="center">
        Choose Your Subscription Plan
      </Typography>

      <Typography variant="body1" align="center" style={{ marginTop: 20 }}>
        “You can cancel at any time”
      </Typography>

      <Typography variant="body1" align="center" style={{ marginTop: 10 }}>
        “Cheaper than a cup of coffee!”
      </Typography>

      <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            fullWidth
            className="memberoptions"
            color={subscriptionType === "monthly" ? "primary" : "inherit"}
            onClick={() => setSubscriptionType("monthly")}
          >
            Monthly - £2.00
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            fullWidth
            className="memberoptions"
            color={subscriptionType === "yearly" ? "primary" : "inherit"}
            onClick={() => setSubscriptionType("yearly")}
          >
            Yearly - £24.99
          </Button>
        </Grid>
      </Grid>

      <Typography
        variant="h5"
        align="center"
        sx={{ my: 2 }}
        style={{ marginTop: "50px" }}
      >
        Payment Details
      </Typography>
      <div className="stripe-input-container">
        <Grid
          container
          spacing={2}
          justifyContent="center"
          className="input-names"
        >
          <Grid
            container
            spacing={2}
            style={{ maxWidth: "574px", position: "relative", left: "8px" }}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                className="input-names"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                className="input-names"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CardNumberElement options={{ style: cardElementStyles }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CardExpiryElement options={{ style: cardElementStyles }} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CardCvcElement options={{ style: cardElementStyles }} />
          </Grid>
        </Grid>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handlePayment}
          loading={paymentLoading}
        >
          Pay Now
        </LoadingButton>
      </div>
      <span className="button-box" style={{ maxWidth: "800px" }}>
        <Button type="button" variant="outlined" size="large" onClick={back}>
          Back
        </Button>

        <div>
          {paidStatus !== "false" && (
            <Button
              type="button"
              variant="contained"
              size="large"
              onClick={handleNext}
            >
              {loading && (
                <CircularProgress
                  size="20px"
                  style={{ marginRight: "10px", color: "white" }}
                />
              )}
              Next
            </Button>
          )}
        </div>
      </span>
      <img className="stripelogo" src={stripeLogo} alt="Powered by" />
    </Box>
  );
};

export default Step2;
