import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Message from "../../Dashboard/Components/Messages/Message";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description"; // Icon for DOC/DOCX
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import Snackbar from "@mui/material/Snackbar";
import Swal from "sweetalert2";
import {
  faBook,
  faCalendar,
  faCopy,
  faEdit,
  faEnvelope,
  faFlag,
  faGlobe,
  faMapMarker,
  faMoneyBill,
  faPhone,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./CourseDetails.css"; // Import CSS

const levels = [
  {
    value: "primary-7-11",
    key: "Primary (7/11+)",
  },
  {
    value: "primary-5-11",
    key: "Primary (5-11 yrs)",
  },
  {
    value: "secondary-11-14",
    key: "Seconday (11-14 yrs)",
  },
  {
    value: "gcse-15-16",
    key: "GCSE (15-16 yrs)",
  },
  {
    value: "a-level-5-18",
    key: "A-level (15-18 yrs)",
  },
  {
    value: "degree-adults",
    key: "Degree/Adults",
  },
  {
    value: "other",
    key: "Other",
  },
];
export const CourseDetails = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const { id } = useParams();

  let userId;
  try {
    const persistedData = localStorage.getItem("persist:root");
    const userData = JSON.parse(persistedData);
    if (userData.userId && typeof userData.userId === "string") {
      userData.userId = userData.userId.replace(/\\/g, "");
    }
    userId = userData?.userId;
  } catch (error) {
    console.error("Error parsing localStorage data:", error);
    userId = null;
  }

  let imageUrl;
  let pdfUrl;

  pdfUrl = `${process.env.REACT_APP_SERVER}/${courses[0]?.file}`;

  console.log("pdfUrl", pdfUrl);

  const handleClick = () => {
    setSnackBarOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };
  useEffect(() => {
    fetchCourses(id);
  }, [id]);

  useEffect(() => {
    pdfUrl = courses[0]?.file
      ? `${process.env.REACT_APP_SERVER}/${courses[0]?.file}`
      : `${process.env.REACT_APP_SERVER}/default-file.pdf`;
    imageUrl = courses[0]?.image
      ? `${process.env.REACT_APP_SERVER}/${courses[0]?.image}`
      : `${process.env.REACT_APP_SERVER}/default-image.jpg`;
  }, [courses]);

  const handleMessageModalOpen = () => {
    setMessageModalOpen(true);
  };
  const handleShareModalOpen = () => {
    setShareModalOpen(true);
  };

  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };
  const navigteEdit = () => {
    navigate(`/course/new/${id}`);
  };
  const copyToClipboard = () => {
    const courseId = courses._id;
    const url = `${process.env.REACT_APP_URL}/course-detail/${id}`;
    navigator.clipboard.writeText(url).then(
      function () {
        handleClick();
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  };
  const handleFacebookShare = () => {
    const courseId = courses._id;
    const url = `${process.env.REACT_APP_URL}/course-detail/${id}`;
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  const handleWhatsAppShare = () => {
    const courseId = courses._id;
    const url = `${process.env.REACT_APP_URL}/course-detail/${id}`;
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
      "_blank"
    );
  };
  const handleMessageModalClose = () => {
    setMessageModalOpen(false);
  };

  const fetchCourses = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/courses/${id}`
      );
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  function onError(e) {
    // e.target.src = placeholder;
  }
  console.log("userId ", userId);

  const handleButtonClick = () => {
    if (!userId || userId === "null") {
      Swal.fire({
        title: "Login Required",
        text: "You need to be logged in to send a message.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sign In",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#712d93",
        cancelButtonColor: "#d33",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/signin"; // Redirect to login page
        }
      });
    } else {
      handleMessageModalOpen(); // Open message modal if logged in
    }
  };

  return (
    <Box
      className="course-detailscard"
      sx={{
        paddingLeft: { xs: "0px", sm: "0px", md: "0px", lg: "40px" },
      }}
    >
      <Container
        maxWidth="lg"
        sx={{ maxWidth: "1600px", margin: "0 auto", padding: "20px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{
              textAlign: "left",
              marginBottom: "20px",
              marginLeft: "10px",
              paddingTop: "4vh",
            }}
          >
            {levels.find((level) => level.value === courses[0]?.level)?.key ||
              "Unknown"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer",
              padding: "5px",
              borderRadius: "28px",
              transition: "background-color 0.2s",
              "&:hover": {
                backgroundColor: "#609BDF",
                border: "1px solid",
              },
            }}
            onClick={handleShareModalOpen}
          >
            <img
              src="/assets/images/share.png"
              alt="share img"
              style={{ width: "25px" }}
            />
            <Typography sx={{ fontSize: "15px" }}>Share</Typography>
          </Box>
        </Box>
      </Container>

      <Container
        maxWidth="lg"
        sx={{ maxWidth: "1600px", margin: "0 auto", padding: "20px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            alignItems: { xs: "center", sm: "center", md: "flex-start" },
            justifyContent: { xs: "center", sm: "center", md: "flex-start" },
            width: "100%", // Makes it responsive
            maxWidth: "1600px", // Caps width at 1600px
            margin: "0 auto", // Centers it horizontally
          }}
        >
          <img
            src={`${process.env.REACT_APP_SERVER}/${
              courses[0]?.image ?? "default-image.jpg"
            }`}
            alt="courseimage"
            onError={(e) => onError(e)}
            style={{
              height: "100%",
              maxHeight: "400px",
              width: "100%",
              maxWidth: "600px",
              paddingTop: "10px",
              paddingLeft: "10px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              "@media (min-width:600px)": { width: "auto !important" },
              "@media (min-width:900px)": { width: "auto !important" },
              paddingLeft: { xs: 0, sm: "24vw", md: "3vw" },
              paddingTop: { xs: "3vh", sm: "3vh", md: "0" },
              textAlign: { xs: "center", sm: "center", md: "left" },
              gap: "10px",
            }}
          >
            {courses[0]?.by && (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                <span style={{ marginRight: "5px", color: "#712d93" }}>
                  By:
                </span>
                <span style={{ color: "black", fontWeight: "bolder" }}>
                  {courses[0].by}
                </span>
              </Typography>
            )}

            {courses[0]?.name && (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                <span style={{ marginRight: "5px", color: "#712d93" }}>
                  Name of course:
                </span>
                <span style={{ color: "black", fontWeight: "bolder" }}>
                  {courses[0].name}
                </span>
              </Typography>
            )}

            {courses[0]?.level && (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                <span style={{ marginRight: "5px", color: "#712d93" }}>
                  Level:{" "}
                </span>

                <span style={{ color: "black", fontWeight: "bolder" }}>
                  {levels.find((level) => level.value === courses[0]?.level)
                    ?.key || "Unknown"}
                </span>
              </Typography>
            )}

            {courses[0]?.isOngoing ? (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                <span style={{ marginRight: "5px", color: "#712d93" }}>
                  Date:
                </span>
                <span style={{ color: "black", fontWeight: "bolder" }}>
                  Ongoing
                </span>
              </Typography>
            ) : (
              courses[0]?.date?.length && (
                <Typography variant="h6" sx={{ textAlign: "left" }}>
                  <span style={{ marginRight: "5px", color: "#712d93" }}>
                    Date:
                  </span>
                  <span style={{ color: "black", fontWeight: "bolder" }}>
                    {new Date(
                      courses?.[0]?.date[0]?.split("T")?.[0]
                    ).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}{" "}
                    -{" "}
                    {new Date(
                      courses?.[0]?.date[1]?.split("T")?.[0]
                    ).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                </Typography>
              )
            )}

            {courses[0]?.types && (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                <span style={{ marginRight: "5px", color: "#712d93" }}>
                  Course Type
                </span>
                <span style={{ fontWeight: "bolder" }}>
                  {courses[0]?.types[0] &&
                    courses[0]?.types[0]?.charAt(0).toUpperCase() +
                      courses[0]?.types[0]?.slice(1)}
                  {courses[0]?.types[0] && courses[0]?.types[1] && " / "}
                  {courses[0]?.types[1] &&
                    courses[0]?.types[1]?.charAt(0).toUpperCase() +
                      courses[0]?.types[1]?.slice(1)}
                </span>
              </Typography>
            )}

            {courses[0]?.telephone && (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                <span style={{ marginRight: "5px", color: "#712d93" }}>
                  Telephone:
                </span>
                <span style={{ color: "black", fontWeight: "bolder" }}>
                  {" "}
                  {courses[0].telephone}
                </span>
              </Typography>
            )}

            {courses[0]?.email && (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                <span style={{ marginRight: "5px", color: "#712d93" }}>
                  Email:
                </span>

                <span style={{ color: "black", fontWeight: "bolder" }}>
                  {courses[0].email}
                </span>
              </Typography>
            )}
            {courses[0]?.addOne &&
              courses[0]?.addTwo &&
              courses[0]?.city &&
              courses[0]?.town && (
                <Typography variant="h6" sx={{ textAlign: "left" }}>
                  <span style={{ marginRight: "5px", color: "#712d93" }}>
                    Address :
                  </span>
                  <span style={{ color: "black", fontWeight: "bolder" }}>
                    {" "}
                    {courses[0].addOne}, {courses[0].addTwo}, {courses[0].city},{" "}
                    {courses[0].addTwo}
                  </span>
                </Typography>
              )}

            {courses[0]?.socialLink && (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                <span style={{ marginRight: "5px", color: "#712d93" }}>
                  Social:
                </span>
                <span style={{ color: "black", fontWeight: "bolder" }}>
                  {courses[0].socialLink}
                </span>
              </Typography>
            )}

            {courses[0]?.coursePrice && (
              <Typography variant="h6" sx={{ textAlign: "left" }}>
                <span style={{ marginRight: "5px", color: "#712d93" }}>
                  Price:
                </span>

                <span style={{ color: "black", fontWeight: "bolder" }}>
                  £{courses[0].coursePrice}
                </span>
              </Typography>
            )}

            {pdfUrl && pdfUrl !== "" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  margin: "10px 0",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  width: "fit-content",
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#e0e0e0" },
                }}
              >
                <a
                  href={pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  {pdfUrl.endsWith(".pdf") ? (
                    <PictureAsPdfIcon sx={{ color: "#D32F2F", fontSize: 30 }} />
                  ) : (
                    <DescriptionIcon sx={{ color: "#712d93", fontSize: 30 }} />
                  )}
                  <span style={{ marginLeft: "5px" }}>
                    {pdfUrl.endsWith(".pdf") ? "View PDF" : "View Document"}
                  </span>
                </a>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: "10px 20px",
                  borderRadius: "28px",
                  width: { xs: "30vw", sm: "20vw", md: "7vw", lg: "8vw" },
                }}
                onClick={handleButtonClick}
              >
                Message
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: "1600px",
            overflowWrap: "break-word",

            marginTop: "25px",
          }}
        >
          <div
            style={{
              paddingLeft: "10px",
              paddingTop: "5vh",
              fontSize: "20px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
            }}
            dangerouslySetInnerHTML={{ __html: courses[0]?.description }}
          />

          {/* <Typography
                    sx={{
                        paddingLeft: '10px',
                        paddingTop: '5vh',
                        fontSize: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal'
                    }}
                >
                    {courses[0]?.description}
                </Typography> */}
        </Box>
      </Container>

      <Modal
        open={messageModalOpen}
        onClose={handleMessageModalClose}
        aria-labelledby="message-modal-title"
        aria-describedby="message-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "788px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            height: "90%",
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <Typography
            onClick={handleMessageModalClose}
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Typography>
          <Message userId={courses[0]?.userId} />
        </Box>
      </Modal>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Link Copied to Clickboard"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
      <Modal
        open={shareModalOpen}
        onClose={handleShareModalClose}
        aria-labelledby="share-modal-title"
        aria-describedby="share-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                cursor: "pointer",
                mb: 2,
              }}
            >
              <Typography
                id="share-modal-title"
                variant="h6"
                sx={{ mb: 2, fontWeight: "bolder", fontSize: "2rem" }}
              >
                Share This Course
              </Typography>
              <FontAwesomeIcon
                icon={faTimes}
                style={{ fontSize: "2rem" }}
                onClick={handleShareModalClose}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <Button
                onClick={() => handleWhatsAppShare()}
                sx={{
                  padding: "10px",
                  fontSize: "2rem",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  backgroundColor: "#e0f7fa",
                  border: "2px solid #2196f3",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              >
                <WhatsAppIcon sx={{ fontSize: "2rem", color: "black" }} />
              </Button>

              <Button
                onClick={() => handleFacebookShare()}
                sx={{
                  padding: "10px",
                  fontSize: "2rem",
                  backgroundColor: "#e0f7fa",
                  border: "2px solid #2196f3",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              >
                <FacebookSharpIcon sx={{ fontSize: "2rem", color: "black" }} />
              </Button>

              <Button
                onClick={() => copyToClipboard()}
                sx={{
                  padding: "7px",
                  fontSize: "2rem",
                  backgroundColor: "#e0f7fa",
                  border: "2px solid #2196f3",
                  borderRadius: "50%",
                }}
              >
                <FontAwesomeIcon
                  icon={faCopy}
                  style={{ fontSize: "2rem", color: "black" }}
                />
              </Button>
            </Box>
            <Box sx={{ paddingTop: "25px", width: "100%" }}>
              <TextField
                type="text"
                placeholder={`${process.env.REACT_APP_URL}/course-detail/${id}`}
                fullWidth
                disabled
                InputProps={{
                  style: {
                    fontSize: "1rem",
                    padding: "10px",
                    height: "5vh",
                  },
                }}
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "4px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#2196f3",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "black",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "black",
                    opacity: 1,
                  },
                }}
              />
              <Box
                sx={{
                  paddingTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: "#2196f3",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    // textTransform: "uppercase",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#712d93",
                    },
                    "&:disabled": {
                      backgroundColor: "#b0bec5",
                      color: "#ffffff",
                    },
                  }}
                  onClick={copyToClipboard}
                >
                  Copy!
                </Button>
              </Box>
            </Box>
          </>
        </Box>
      </Modal>
    </Box>
  );
};
