import "./Course.css";
import { Grid, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CardComponent from "./Components/ContentCards";

const Course = () => {
  const navigate = useNavigate();
  const navigateToCourseDetails = (event) => {
    const courseId = event.currentTarget.dataset.id;
    navigate("/course/" + courseId);
  };
  return (
    <div>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={4} style={{ marginTop: "40px" }}>
            <CardComponent
              id={1}
              navigateToCourseDetails={navigateToCourseDetails}
            />
          </Grid>
          <Grid item xs={4} style={{ marginTop: "40px" }}>
            <CardComponent id={2} />
          </Grid>
          <Grid item xs={4} style={{ marginTop: "40px" }}>
            <CardComponent id={3} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Course;
