import { useState, useEffect } from "react";
import { CoursesNavbar } from "./Components/Navbar/Navbar";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./MyPayments.css"; // Import CSS
import { Box, Button, Typography } from "@mui/material";
import { API_URL, createAxiosInstance } from "../../api";
import { useSelector } from "react-redux";
import config from "config";
const MyPayments = () => {
  const [billingHistory, setBillingHistory] = useState([]); // Initialize as empty array
  const [loading, setLoading] = useState(true); // Loading state
  const location = useLocation();
  const { email } = useSelector((state) => state.profile.user);
  //const userId = useSelector((state) => state.profile.user.user);
  let userId;
  try {
    const persistedData = localStorage.getItem("persist:root");
    const userData = JSON.parse(persistedData);
    if (userData.userId && typeof userData.userId === "string") {
      userData.userId = userData.userId.replace(/\\/g, "");
    }
    userId = userData?.userId;
  } catch (error) {
    console.error("Error parsing localStorage data:", error);
    userId = null;
  }

  userId = userId.replace(/"/g, ""); // Remove extra quotes;;
  console.log(userId);

  useEffect(() => {
    const fetchBillingHistory = async () => {
      if (!userId) return; // Ensure user ID is available
      try {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.get(
          `${API_URL}/api/billings/historyAdverts/${userId}`
        );
        setBillingHistory(response.data.history || []); // Set an empty array if no data
        setLoading(false); // Set loading to false once data is fetched
      } catch (err) {
        console.error("Error fetching billing history:", err);
        setLoading(false); // Set loading to false even in case of an error
      }
    };

    fetchBillingHistory();
  }, [email, userId]);

  const [id, setId] = useState("");
  const paramsId = useParams();

  console.log("hello");

  useEffect(() => {
    if (paramsId) {
      setId(paramsId);
    } else {
      setId(null);
    }
  }, [paramsId]);

  return (
    <Box className="courses-container">
      {/* Navbar */}
      <Box
        sx={{
          maxWidth: "975px",
          margin: "0px 15px 0 auto",
          padding: "20px",
          display: "block",
          width: "100%",
        }}
      >
        {/* Row Container - Title (Left) & Button (Right) */}
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* Title (Fully Left-Aligned) */}
          <Typography
            sx={{
              fontSize: "2.5rem",
              color: "#000",
              fontWeight: "bold",
              marginLeft: "0px",
            }}
          >
            My Payments
          </Typography>
        </Box>

        {/* Full-Width Divider */}
        <hr
          style={{
            width: "100%",
            borderTop: "1px solid #ccc",
            marginTop: "10px",
          }}
        />
      </Box>

      {/* Main Content */}
      <Box className="content-container">
        {/* Filter */}
        <Box className="filter-container notop">
          <div className="billing-menu notfixed">
            <Link
              className={`tab${
                location.pathname === "/my-courses"
                  ? " active " + config["class-name"]
                  : ""
              }`}
              to="/my-courses"
            >
              My Courses
            </Link>
            <Link
              className={`tab${
                location.pathname === "/my-courses/billing"
                  ? " active " + config["class-name"]
                  : ""
              }`}
              to="/my-courses/billing"
            >
              My Payments
            </Link>
            <Link
              className={`tab${
                location.pathname === "/my-courses/renew"
                  ? " active " + config["class-name"]
                  : ""
              }`}
              to="/my-courses/renew"
            >
              Renew Course
            </Link>
          </div>
        </Box>

        {/* Courses */}
        <Box className="courses-list">
          <div className="billing-content full-width">
            <div className="billing-history-section">
              <h1 className="page-title">Payment History</h1>
              {/* Show loading message if still fetching */}
              {loading ? (
                <Typography>Loading...</Typography>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Course Name</th>
                      <th>Description</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billingHistory.length === 0 ? (
                      <tr>
                        <td colSpan="4">No payment history found.</td>
                      </tr>
                    ) : (
                      billingHistory.map((entry, index) => (
                        <tr key={index}>
                          <td>
                            {entry.paymentDate
                              ? new Date(entry.paymentDate).toLocaleDateString()
                              : "N/A"}
                          </td>
                          <td>{entry.courseName || "No name"}</td>
                          <td>{entry.description}</td>
                          <td>£{entry.amountPaid}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default MyPayments;
