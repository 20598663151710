import React from "react";
import "./AboutUs.css";
import { ReactComponent as Curve } from "../../assets/images/undercurve.tsx";
import { Link } from "@mui/material";
import config from "config";
// import { convert } from 'html-to-text';

const TypeLink = () => {
  if (config["class-name"] === "math") {
    return (
      <Link
        href="https://www.tutorsdirectory.co.uk"
        target="_blank"
        rel="noreferrer"
      >
        www.tutorsdirectory.co.uk
      </Link>
    );
  }
  if (config["class-name"] === "tutors") {
    return (
      <Link
        href="https://www.tutorsdirectory.co.uk"
        target="_blank"
        rel="noreferrer"
      >
        www.tutorsdirectory.co.uk
      </Link>
    );
  }
  if (config["class-name"] === "english") {
    return (
      <Link
        href="https://www.englishdirectory.co.uk"
        target="_blank"
        rel="noreferrer"
      >
        www.englishdirectory.co.uk
      </Link>
    );
  }

  if (config["class-name"] === "science") {
    return (
      <Link
        href="https://www.sciencedirectory.co.uk"
        target="_blank"
        rel="noreferrer"
      >
        www.sciencedirectory.co.uk
      </Link>
    );
  }
};

const AboutDirectory = () => {
  if (config["class-name"] === "math") {
    return (
      <div>
        <h2>About Maths Directory</h2>
        <p>
          <Link href="www.mathsdirectory.co.uk">www.mathsdirectory.co.uk</Link>{" "}
          is a platform designed for Maths tutors and parents seeking Maths
          support for their children. No commission or finder’s fee is taken
          from either the tutor or the parents. There are subscription plans
          available: pay monthly (pay-as-you-go) or make an annual payment (see
          below).
        </p>
      </div>
    );
  }
  if (config["class-name"] === "tutors") {
    return (
      <div>
        <h2>About Tutors Directory</h2>
        <p>
          <Link href="www.tutorsdirectory.co.uk">
            www.tutorsdirectory.co.uk
          </Link>{" "}
          is a platform designed for Maths tutors and parents seeking Maths
          support for their children. No commission or finder’s fee is taken
          from either the tutor or the parents. There are subscription plans
          available: pay monthly (pay-as-you-go) or make an annual payment (see
          below).
        </p>
      </div>
    );
  }
  if (config["class-name"] === "english") {
    return (
      <div>
        <h2>About English Directory</h2>
        <p>
          <Link href="www.englishdirectory.co.uk">
            www.englishdirectory.co.uk
          </Link>{" "}
          is a platform designed for English tutors, and parents seeking English
          support for their child. Our website is free and, no commission or
          finder's fee is taken from the tutor or the parents. There are
          subscription plans available: pay monthly (pay-as-you-go) or make an
          annual payment (see below).
        </p>
      </div>
    );
  }
  if (config["class-name"] === "science") {
    return (
      <div>
        <h2>About Science Directory</h2>
        <p>
          <Link href="www.sciencedirectory.co.uk">
            www.sciencedirectory.co.uk
          </Link>{" "}
          is a platform designed for Science tutors, and parents seeking Science
          support for their child. Our website is free and, no commission or
          finder's fee is taken from the tutor or the parents. There are
          subscription plans available: pay monthly (pay-as-you-go) or make an
          annual payment (see below).
        </p>
      </div>
    );
  }
};

const Definition = () => {
  if (config["class-name"] === "math") {
    return (
      <li>
        <strong>“Site”</strong> means the Maths Directory website,{" "}
        <Link
          href="https://www.mathsdirectory.co.uk"
          target="_blank"
          rel="noreferrer"
          className="no-underline hover-underline"
        >
          www.mathsdirectory.co.uk
        </Link>
        .
      </li>
    );
  }

  if (config["class-name"] === "english") {
    return (
      <li>
        <strong>“Site”</strong> means the English Directory website,{" "}
        <Link
          href="https://www.englishdirectory.co.uk"
          target="_blank"
          rel="noreferrer"
          className="no-underline hover-underline"
        >
          www.englishdirectory.co.uk
        </Link>
        .
      </li>
    );
  }

  if (config["class-name"] === "science") {
    return (
      <li>
        <strong>“Site”</strong> means the Science Directory website,{" "}
        <Link
          href="https://www.sciencedirectory.co.uk"
          target="_blank"
          rel="noreferrer"
          className="no-underline hover-underline"
        >
          www.sciencedirectory.co.uk
        </Link>
        .
      </li>
    );
  }
};

const EmailLink = () => {
  if (config["class-name"] === "maths") {
    return (
      <Link href="mailto:hello@mathsdirectory.co.uk" target="_blank">
        hello@mathsdirectory.co.uk
      </Link>
    );
  }
  if (config["class-name"] === "tutors") {
    return (
      <Link href="mailto:hello@tutorsdirectory.co.uk" target="_blank">
        hello@tutorsdirectory.co.uk
      </Link>
    );
  }
  if (config["class-name"] === "english") {
    return (
      <Link href="mailto:hello@englishdirectory.co.uk" target="_blank">
        hello@englishdirectory.co.uk
      </Link>
    );
  }

  if (config["class-name"] === "science") {
    return (
      <Link href="mailto:hello@sciencedirectory.co.uk" target="_blank">
        hello@sciencedirectory.co.uk
      </Link>
    );
  }
};

const Terms = () => {
  let title = "Maths";
  if (config["class-name"] === "english") {
    title = "English";
  }

  if (config["class-name"] === "science") {
    title = "Science";
  }
  if (config["class-name"] === "tutors") {
    title = "Tutors";
  }
  return (
    <>
      <br />
      <div className="terms-container">
        <div className="terms-heading-box">
          <div className="page-heading">
            <h1>Terms of use</h1>
            <Curve style={{ maxWidth: "100%" }} />
          </div>
        </div>

        <div className="terms-bottom-box">
          <div style={{ fontSize: "14px", margin: "10px 10px 10px 0px" }}>
            <i>Last Updated:</i>
            <i style={{ fontWeight: "600" }}> April 22nd, 2024</i>
          </div>
          <AboutDirectory />
          <div>
            <h2>Your Privacy</h2>
            <p>
              To use this website and our services, we may ask you provide
              personal details when registering for an account information
              provided must be accurate and current
            </p>
          </div>
          <div>
            <h2>Cookies</h2>
            <p>
              Our website uses cookies. By using <TypeLink /> or agreeing to the
              Terms of Use, you agree to our use of cookies following the terms
              of our Privacy and Cookie Policy. Please read our Cookie Policy
              for more information on your personal data.
            </p>
          </div>

          <div>
            <h2>Definitions</h2>
            <ul style={{ padding: 0 }}>
              <li>
                <strong>“we”</strong>,<strong> “us”</strong>, and{" "}
                <strong>“our”</strong> mean {title} Directory
              </li>
              <li>
                <strong>“user”</strong>,<strong> “you”</strong>, and{" "}
                <strong>“your”</strong> refer to site visitors, customers, and
                any other users of the site.
              </li>
              {/*<li><strong>“Education zone”</strong> is the business that owns and runs this website and provides*/}
              {/*  Services.*/}
              {/*</li>*/}
              <Definition />
              <li>
                <strong>“Services”</strong> means any services that {title}
                Directory may provide, such as the tutor directory.
              </li>
              {/*<li><strong>“Tutor Directory”</strong> means a directory or list of Tutors accessible by members of the*/}
              {/*  public who can choose a Tutor.*/}
              {/*</li>*/}
              <li>
                <strong>“Visitor/Client”</strong> means anyone who visits or
                browses our site.
              </li>
              {/*<li><strong>“Client” or ”Customer”</strong> means a Visitor to our Site who intends or has used the*/}
              {/*  Tutor*/}
              {/*  Directory.*/}
              {/*</li>*/}
            </ul>
          </div>

          <div>
            <h2>Using our Site</h2>
            <p>
              You must be 18 years of age or older to use our website and
              services.
            </p>
          </div>

          <div>
            <ol className="custom-listing">
              {/*<li><strong>Consideration</strong>*/}
              {/*  <ol>*/}
              {/*    <li>You acknowledge that we provide our Site and Services and you use our site and facilities for*/}
              {/*      your*/}
              {/*      benefit in being able to conveniently find a tutor. You are also contribute to the client base for*/}
              {/*      tutors on our site which they would not ordinarily have access to which is of value which is why*/}
              {/*      Tutors want to use our site and therefore is good consideration for this agreement.*/}
              {/*    </li>*/}
              {/*  </ol>*/}
              {/*</li>*/}
              {/*<li><strong>Lawful Purposes</strong>*/}
              {/*  <ol>*/}
              {/*    <li>You may use our Site for lawful purposes only.*/}
              {/*    </li>*/}
              {/*    <li>You must not post or transmit any material which violates or infringes the rights of others*/}
              {/*      through our Site. Or which is threatening, abusive, racist, offensive, defamatory, libellous,*/}
              {/*      invasive of privacy or publicity rights, vulgar, obscene, profane, or otherwise objectionable,*/}
              {/*      contains injurious formulas, recipes, or instructions, which encourages behaviour that that would*/}
              {/*      be*/}
              {/*      a criminal offence, give rise to civil liability, or otherwise violate any law.*/}
              {/*    </li>*/}
              {/*  </ol>*/}
              {/*</li>*/}
              <li>
                <strong>Service Description</strong>
                <ol>
                  <li>
                    We try to describe and display our services as accurately as
                    possible. While we want to be as clear as possible in
                    explaining our services, we cannot guarantee that
                    tutor&rsquo;s information or or any other information on
                    site is completely accurate, complete, reliable, current, or
                    error-free.
                  </li>
                  <li>
                    We are not responsible for any inaccuracy, error, or
                    incompleteness in our website content.
                  </li>
                  <li>
                    We may refuse or cancel any service with an incorrect
                    listing.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Intellectual Property Rights</strong>
                <ol>
                  <li>
                    Our Site contains intellectual property owned by {title}
                    Directory. This includes trademarks, copyrights, proprietary
                    information, and other intellectual property. You may not
                    modify, publish, transmit, participate in the transfer or
                    sale of, create derivative works from, distribute, display,
                    reproduce or perform, or exploit in any format, any of the
                    site content or intellectual property, in whole or in part,
                    without our written permission. We may use our right to
                    immediately remove you from the site, if you are caught
                    breaking this intellectual property policy.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Change of Terms</strong>
                <ol>
                  <li>
                    We may at any time change these Terms of Use. Such changes
                    are effective from the time we post the new Terms of Use on
                    this site. Any use of the site by you after these terms have
                    been posted means you accept these amendments. We reserve
                    the right to update any portion of our site, including these
                    Terms of Use, at any time. We will post the most recent
                    versions to the site and list the effective dates on our
                    Terms of Use page. We advise you to check these Terms of Use
                    at regular intervals.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Termination</strong>
                <ol>
                  <li>
                    You agree that we can terminate your use or access to this
                    site at any time without notice if you break any of these
                    Terms of Use.
                  </li>
                  <li>
                    The duration of the contract for the duration of the service
                    or until performance has been completed by both parties.
                  </li>
                  <li>
                    This agreement can be terminated at any time for any reason
                    by mutual agreement.
                  </li>
                  <li>
                    False or misleading information (e.g. falsly claiming to
                    have a teaching qualification or that you are an examiner)
                    provided will result in your account being terminated.
                  </li>
                </ol>
              </li>
              {/*<li><strong>Climate Assurance</strong>*/}
              {/*  <ol>*/}
              {/*    <li>Both parties agree to, wherever possible, perform their obligations under this Agreement in a*/}
              {/*      way*/}
              {/*      that reduces or minimises the Carbon Footprint associated with any activities under this*/}
              {/*      Agreement.*/}
              {/*      For example, using digital forms and communication instead of paper forms or using non-plastic*/}
              {/*      items.*/}
              {/*    </li>*/}
              {/*    <li>Either party should use all reasonable efforts to make sure that any necessary third party, use*/}
              {/*      such documents or plastic and perform such acts as may reasonably be required for reducing the*/}
              {/*      Carbon footprint as a measure to protect the environment.*/}
              {/*    </li>*/}
              {/*  </ol>*/}
              {/*</li>*/}
              <li>
                <strong>Responsibility and Liability</strong>
                <ol>
                  <li>
                    We do not guarantee that your use of the site will be
                    uninterrupted, or error-free.
                  </li>
                  <li>
                    We do not guarantee the results that may be obtained from
                    the use of our site.
                  </li>
                  <li>
                    You agree that from time to time we may reasonably remove
                    the service for indefinite periods or cancel the service at
                    any time, without notice to you.
                  </li>
                  <li>
                    We will not be liable for any loss of profits, income,
                    revenue, use, production, or anticipated savings or
                    earnings.
                  </li>
                  <li>
                    We will not be liable for any loss of business, contracts,
                    or commercial opportunities.
                  </li>
                  <li>
                    We will not be liable for any loss or corruption of any
                    data, database, or software.
                  </li>
                  <li>
                    Subject to GDPR or the Data Protection Act 2018, if
                    applicable, we will not be liable for any data breach or
                    data protection losses that were contributed to or caused by
                    you.
                  </li>
                  <li>
                    Neither party will be liable for any losses arising out of a
                    Force Majeure.
                  </li>
                  <li>
                    In no case will we, our employees or representatives be
                    liable for indirect, incidental, consequential or any other
                    remedies as a result of using our services or by any other
                    third parties. Additionally, we are not liable for damages
                    or remedies for website failure, error, omission, attack by
                    hackers or pirates, interruption, delay in operation or
                    transmission of videos, computer virus, or system failure.
                    We are also not liable for any third-party theft,
                    destruction, unauthorised access alteration or use of your
                    information or personal data (subject to GDPR or the Data
                    Protection Act 2018 if applicable), whether we were
                    negligent or not.
                  </li>
                  <li>
                    Neither party will be liable for breach-of-contractual
                    damages suffered by the other party&nbsp;that are remote or
                    speculative, or that&nbsp;could not have reasonably
                    been&nbsp;foreseen before entering into this agreement.
                  </li>
                  <li>
                    We provide a directory service where the tutor is verified
                    before they are listed on our Site. It is your
                    responsibility to ensure that adequate adult supervision is
                    in place during tutorials or lessons.
                  </li>
                  <li>
                    {title} Directory are not liable for any claims made by you
                    arising out of or related to tutoring by a Tutor listed on
                    our site.
                  </li>
                  <li>
                    We are not liable in respect of the tutor’s academic
                    credentials (e.g. degree, teaching qualifications) and
                    background checks (e.g. DBS).
                  </li>
                  <li>
                    You and the tutor are responsible for agreeing on all
                    matters relating to the tuition arrangement (including DBS
                    checks). You are responsible for verifying the credentials
                    of the tutor with whom you enter into or seek to enter into
                    a tuition arrangement.
                  </li>
                  <li>
                    You hereby confirm that you are no less than eighteen years
                    of age. Any person seeking to use the website or our
                    services who is less than eighteen years of age must arrange
                    for a parent or guardian to register with us on their behalf
                    and that parent or guardian will be the client for the
                    purposes of these terms.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Subscription Services</strong>
                <ol>
                  <li>
                    Our subscriptions model are offered on a monthly or /annual
                    basis.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Payment Terms</strong>
                <ol>
                  <li>
                    <strong>Billing Cycle:</strong> Subscriptions are billed in
                    advance on a monthly or annual basis, depending on the plan
                    you choose. Payments are processed on the first day of each
                    billing cycle.
                  </li>
                  <li>
                    <strong>Payment Methods:</strong> By providing a payment
                    method, you represent that you are authorised to use the
                    payment method and authorise us to charge the subscription
                    fees to your payment method.
                  </li>
                  <li>
                    <strong>Pricing:</strong> Subscription fees may change at
                    any time. We will notify you in advance of any pricing
                    changes. If you do not agree with the changes, you may
                    cancel your subscription before the new pricing takes
                    effect.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Renewal of subscription</strong>
                <ol>
                  <li>
                    <strong>Monthly Subscriptions:</strong>
                    <ol>
                      <li>
                        Monthly subscriptions are automatically renewed every
                        month on the renewal date. The payment method on file
                        will be charged the current monthly rate unless the
                        subscription is cancelled before the renewal date.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Annual Subscriptions:</strong>
                    <ol>
                      <li>
                        Annual subscriptions are automatically renewed every
                        year on the renewal date. The payment method on file
                        will be charged the current annual rate unless the
                        subscription is cancelled before the renewal date.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <strong>Renewal Notifications</strong>
                <ol>
                  <li>
                    <strong>Monthly Subscribers</strong>
                    <ol>
                      <li>
                        Subscribers will receive a renewal reminder email before
                        the renewal date. This email will include the amount to
                        be charged and a link to manage their subscription.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Annual Subscribers</strong>
                    <ol>
                      <li>
                        Subscribers will receive a renewal reminder email before
                        the annual renewal date. This email will include the
                        renewal fee, any changes to the terms, and a link to
                        manage their subscription.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <strong>Price Changes</strong>
                <ol>
                  <li>
                    If there are any changes to the subscription price,
                    subscribers will be notified via email in advance. The new
                    price will take effect on the next renewal date.
                  </li>
                  <li>
                    Subscribers will have the option to cancel their
                    subscription before the renewal date if they do not agree to
                    the new price.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Cancellation Policy</strong>
                <ol>
                  <li>
                    <stron>Monthly Subscribers</stron>
                    <ol>
                      <li>
                        Subscribers can cancel their monthly subscription at any
                        time. To avoid being charged for the next month, the
                        cancellation must be completed before the renewal date.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <stron>Annual Subscribers</stron>
                    <ol>
                      <li>
                        Subscribers can cancel their annual subscription at any
                        time. If the subscription is cancelled before the
                        renewal date, it will not renew, but the subscriber will
                        continue to have access to the service until the end of
                        the current subscription period.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <strong>Refund Policy</strong>
                <ol>
                  <li>
                    All subscription payments are non-refundable. If you cancel
                    your subscription, you will continue to have access to the
                    service until the end of the current billing period. After
                    the billing period ends, you will no longer have access to
                    the subscription services.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Payment Failures</strong>
                <ol>
                  <li>
                    If a payment fails on the renewal date, the subscriber will
                    be notified, and a grace period of 5 days will be provided
                    to update the payment information. If the payment is not
                    successfully processed within the grace period, the
                    subscription will be cancelled.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Subscription Management</strong>
                <ol>
                  <li>
                    Subscribers can manage their subscription settings,
                    including cancellation, payment method updates, and plan
                    changes, through their account dashboard on the website.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Plan Changes</strong>
                <ol>
                  <li>
                    <strong>Upgrading or Downgrading:</strong>
                    <ol>
                      <li>
                        Subscribers can upgrade or downgrade their subscription
                        plan at any time. The new plan will take effect
                        immediately, and the billing will be adjusted
                        accordingly (prorated billing may apply for the change).
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>
                      Switching from Monthly to Annual (and vice versa):
                    </strong>
                    <ol>
                      <li>
                        Subscribers can switch from a monthly to an annual plan
                        or vice versa. The new plan will take effect at the end
                        of the current billing cycle, unless the user chooses to
                        switch immediately.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <strong>Renewal Confirmation</strong>
                <ol>
                  <li>
                    After each renewal, a confirmation email will be sent to the
                    subscriber detailing the transaction, including the amount
                    charged and the next renewal date.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Termination by the Service</strong>
                <ol>
                  <li>
                    The service reserves the right to terminate subscriptions in
                    accordance with its terms of service. Subscribers will be
                    notified of termination via email and may be entitled to a
                    refund depending on the circumstances.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Contact Information</strong>
                <ol>
                  <li>
                    For any questions or concerns regarding the subscription,
                    renewal process, or billing, subscribers can contact the
                    support team at <EmailLink />.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Changes to the Renewal Policy</strong>
                <ol>
                  <li>
                    The renewal policy may be updated from time to time.
                    Subscribers will be notified of significant changes via
                    email before the new policy takes effect.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Third-Party Links</strong>
                <ol>
                  <li>
                    Our Site contains links to third-party websites and
                    resources. You acknowledge and agree that we are not
                    responsible or liable for the availability, accuracy,
                    content, or policies of third-party websites or resources.
                    Links to such websites or resources do not imply any
                    endorsement by or affiliation with {title} Directory. You
                    acknowledge sole responsibility for and assume all risk
                    arising from your use of any such websites or resources.
                  </li>
                  <li>
                    All notices, requests, demands, and other communications
                    under this agreement must be in writing and sent by email
                    to: <EmailLink />.
                  </li>
                </ol>
              </li>
              {/*<li><strong>Payment for loss or damage</strong>*/}
              {/*  <ol>*/}
              {/*    <li>You agree to pay us for any losses, damage, settlements, liabilities, costs, charges,*/}
              {/*      assessments,*/}
              {/*      and expenses, as well as third party claims and causes of action, including, without limitation,*/}
              {/*      lawyer&rsquo;s fees, arising from any breach by you of any of these Terms and Conditions, or any*/}
              {/*      use*/}
              {/*      by you of the Site. You will provide us with any help that we might ask for in connection with any*/}
              {/*      such defence without any charge including, without limitation, giving us such information,*/}
              {/*      documents, records, and reasonable access as we see necessary. You will not resolve any*/}
              {/*      third-party*/}
              {/*      claim or reject any defence without our previous written permission.*/}
              {/*    </li>*/}
              {/*  </ol>*/}
              {/*</li>*/}
              {/*<li><strong>Notices</strong>*/}
              {/*  <ol>*/}
              {/*    <li>Our Contact details are:*/}
              {/*      <p style={{margin: '0px', paddingLeft: '30px'}}>*/}
              {/*        <strong>*/}
              {/*          Educationzone Ltd t/a Maths Directory<br/>*/}
              {/*          Company Number: 07009015<br/>*/}
              {/*          Churchill House,<br/>*/}
              {/*          Brent St,<br/>*/}
              {/*          London NW4 4DJ<br/>*/}
              {/*          UK*/}
              {/*        </strong>*/}
              {/*      </p>*/}
              {/*    </li>*/}
              {/*    <li>All notices, requests, demands, and other communications under this agreement must be in writing*/}
              {/*      and sent by email to:*/}
              {/*      <p style={{margin: '0px', paddingLeft: '30px'}}>*/}
              {/*        <a href='mailto:hello@mathsdirectory.co.uk' target='_blank'>hello@mathsdirectory.co.uk</a>*/}
              {/*      </p>*/}
              {/*    </li>*/}
              {/*  </ol>*/}
              {/*</li>*/}
              <li>
                <strong>Whole Agreement</strong>
                <ol>
                  <li>
                    These Terms of Use including the Cookie Policy, and any
                    attachments are the whole agreement between {title}{" "}
                    Directory and you and cancels all other verbal or written
                    understandings concerning this agreement that were made
                    outside this agreement.
                  </li>
                </ol>
              </li>
              <li>
                <strong>
                  Events or circumstances beyond our reasonable control
                </strong>
                <ol>
                  <li>
                    Where an event beyond our reasonable control known as a
                    Force Majeure Event gives rise to a failure or delay in
                    either party performing its obligations under the Agreement
                    (other than obligations to make payment), those obligations
                    will be suspended for the duration of the Force Majeure
                    Event. Examples of such events and circumstances include,
                    but are not limited to, fire, flood and other natural
                    occurrences, strikes, trade disputes, lockouts, restrictions
                    of imports or exports, riot, accident, disruption to energy
                    supplies, lockdowns, pandemics, civil commotion, acts of
                    terrorism or war.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Invalid Clauses</strong>
                <ol>
                  <li>
                    If an appropriate court or arbitrator decides that a clause
                    or condition in this contract is invalid or it conflicts
                    with the law, the invalid clause can be cut from this
                    agreement leaving the rest of the agreement valid and
                    unaffected.
                  </li>
                </ol>
              </li>
              <li>
                <strong>The law and jurisdiction of this agreement</strong>
                <ol>
                  <li>
                    You agree that any dispute or claim arising out of this
                    agreement or in connection with its subject matter or
                    formation, including non-contractual disputes or claims,
                    will be governed and interpreted according to the law of
                    England and Wales in English. You also agree that English
                    courts have exclusive jurisdiction except for negotiation
                    and mediation resolution which may be used as an option
                    before court action if both parties agree.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
        <p className="terms-footer">
          2024 © {title} Directory. All Rights Reserved. Company Number:
          07009015
        </p>
      </div>
    </>
  );
};

export default Terms;
