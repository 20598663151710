import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import config from "config";
export const CoursesNavbar = () => {
  const navigate = useNavigate();

  const addCourses = () => {
    navigate("/course/new");
  };

  return (
    <Box
      sx={{
        maxWidth: "975px",
        margin: "0px 15px 0 auto",
        padding: "20px",
        display: "block",
        width: "100%",
      }}
    >
      {/* Row Container - Title (Left) & Button (Right) */}
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* Title (Fully Left-Aligned) */}
        <Typography
          sx={{
            fontSize: "2.5rem",
            color: "#000",
            fontWeight: "bold",
            marginLeft: "0px",
          }}
        >
          Courses
        </Typography>

        {/* Add Course Button (Fully Right-Aligned) */}
        <Button
          onClick={addCourses}
          variant="contained"
          color="primary"
          //className={`btnconfig ${config["class-name"]}`}
          sx={{
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "28px",
          }}
        >
          + Add Course
        </Button>
      </Box>

      {/* Full-Width Divider */}
      <hr
        style={{
          width: "100%",
          borderTop: "1px solid #ccc",
          marginTop: "10px",
        }}
      />
    </Box>
  );
};
