const config: WhiteLabelConfig = {
  "class-name": "tutors",
  "home-title": "Looking for an Tutor?",
  "home-subtitle": "Search for a Specialist Tutor",
  "primary-text-colour-class": "tutors-primary-text-colour",
  "secondary-text-colour-class": "tutors-secondary-text-colour",
  "primary-colour": "#712d94",
  "secondary-colour": "#712d94",
  "primary-colour-mui": "#712d94",
  "secondary-colour-mui": "#000000",
  "name-colour": "#712d94",
  "chip-colour": "#712d94",
  "footer-content": "Tutors Directory",
  "navbar-link": "Search tutors",
  "filter-level-label": "Level",
  "filter-level-values": {
    " ": "None",
    "7+/11+": "7+/11+",
    Primary: "Primary",
    Secondary: "Secondary",
    GCSE: "GCSE",
    "A-level": "A-level",
    "Degree/Adult": "Degree/Adult",
  },
  "home-level-values": {
    " ": "All",
    "7+/11+": "7+/11+",
    Primary: "Primary",
    Secondary: "Secondary",
    GCSE: "GCSE",
    "A-level": "A-level",
    "Degree/Adult": "Degree/Adult",
  },
  "profile-step-0-title": "What subjects can you teach?",
  "search-result-title": "tutor",
  "message-colour": "#f5e7ee",
  "profile-step-3-title-placeholder":
    "E.g. Experienced and committed english tutor. Limit: 50 characters",
  "profile-step-3-about-us-placeholder":
    "Tell us about yourself e.g. experience as a tutor, teaching style or approach. Limit: 190 words.",
};

export default config;
